import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_BASE_URL;
import _ from "lodash";
import moment from "moment";

const state = {
	messages: {
		notifications: [],
		page: 1,
		last_page: 1,
		copiedVal: null
	},
	sortMessages: null,
	tempPeriod: "",
	archMessages: {
		notifications: [],
		page: 1,
		last_page: 1,
		copiedVal: null
	},
	customer: "",
	unreadMessages: "",
	emailWarning: false,
	genralLoader: true,
	currentEditItem: "",
	currentEmailRecpients: "",
	customTimeDailog: false,
	loading: false,
	emailDailog: false,
	boxOpen: {
		show: null,
		ref: null,
		state: null,
	},
	shouldPushNotify: [],
	hasNew: false,
	notificationsIsLoading: false,
	notifGlobalLoading: false,
	sortedMessages: {
		notifications: [],
		page: 1,
		last_page: 1,
		copiedVal: null,
		type: "all"
	},
	sortedMessagesLoading: false,
	shipmentDocumentPreview:{},
	customTimeLoading: false,
	oldCustomerId: null
};

const getters = {
	getMessages: (state) => state.messages,
	getSortMessages: (state) => state.sortMessages,
	getTempPeriod: (state) => state.tempPeriod,
	getArchMessages: (state) => state.archMessages,
	getCustomer: (state) => state.customer,
	getUnreadMessages: (state) => state.unreadMessages,
	getEmailWarning: (state) => state.emailWarning,
	getGenralLoader: (state) => state.genralLoader,
	getCurrentEditItem: (state) => state.currentEditItem,
	getCurrentEmailRecpients: (state) => state.currentEmailRecpients,
	getCustomTimeDailog: (state) => state.customTimeDailog,
	getLoading: (state) => state.loading,
	getEmailDailog: (state) => state.emailDailog,
	getBoxOpen: (state) => state.boxOpen,
	getShouldPushNotify: (state) => state.shouldPushNotify,
	getHasNew: (state) => state.hasNew,
	getNotificationsIsLoadingShipment: (state) => state.notificationsIsLoading,
	getNotifGlobalLoading: (state) => state.notifGlobalLoading,
	getSortedMessages: (state) => state.sortedMessages,
	getSortedMessagesLoading: (state) => state.sortedMessagesLoading,
	getShipmentDocumentPreview:(state) => state.shipmentDocumentPreview,
	getCustomTimeLoading: (state) => state.customTimeLoading,
	getOldCustomerId: (state) => state.oldCustomerId
};

const methods = {
	processNotificationData(item, page) {
		let arrayList = [];
		if (item !== null && Array.isArray(item) && item.length > 0) {
			arrayList = item.map(v => {
				return {
					type: "App\\Notifications\\ShipmentNotification",
					read_at: v.read_at,
					microservice: "",
					data: v,
					created_at: v.created_at,
					updated_at: v.updated_at,
					notifiable_id: v.id,
					notifiable_type: "App\\Models\\User",
					id: v.id,
					page,
					archived_at:v.archived_at
				}
			})
		}
		return arrayList
	},
	processSingleData(v) {
		let newData = null;
		if (v !== null) {
			newData = {
				type: "App\\Notifications\\ShipmentNotification",
					read_at: v.read_at,
					microservice: "",
					data: v,
					created_at: v.created_at,
					updated_at: v.updated_at,
					notifiable_id: v.id,
					notifiable_type: "App\\Models\\User",
					id: v.id,
					archived_at:v.archived_at
			}
		}
		return newData;
	},
	updateMessagesNotifications(v) {
		let { activeNotifications, currentNotif, getMessages } = v;

		let findIndex = _.findIndex(activeNotifications, e => (e.id === currentNotif.id));
		activeNotifications[findIndex] = methods.processSingleData(currentNotif);
		let finalShipmentNotif = { 
			notifications: activeNotifications, page: getMessages.page, last_page: getMessages.last_page
		};
		// commit("SET_MESSAGES", finalShipmentNotif);
		return finalShipmentNotif;
	},
	updateArchivedNotifications(v) {
		let { archiveNotifications, currentNotif, getArchMessages } = v;

		let findIndexOfUpdatedNotif = _.findIndex(archiveNotifications, e => (e.id === currentNotif.id));
		if (findIndexOfUpdatedNotif === -1) { // push archive notification to archived lists
			archiveNotifications.push(methods.processSingleData(currentNotif));

			let archiveTotal = archiveNotifications.length;
			let perPage = 20;						
			let last_page = archiveTotal < perPage ? 1 : Math.ceil(archiveTotal / perPage);

			let finalShipmentNotif = { 
				notifications: archiveNotifications, 
				page: getArchMessages.page, 
				last_page
			};
			// commit("SET_ARCH_MESSAGES", finalShipmentNotif);
			return finalShipmentNotif;
		}
	},
	spliceArchiveNotification(v) {
		let { archiveNotifications, currentNotif, getArchMessages } = v;

		let findIndex = _.findIndex(archiveNotifications, e => (e.id === currentNotif.id));
		archiveNotifications.splice(findIndex, 1);
		let archiveTotal = archiveNotifications.length;
		let perPage = 20;
		let last_page = archiveTotal <= perPage ? 1 : Math.ceil(archiveTotal / perPage);

		let finalShipmentNotif = { 
			notifications: archiveNotifications,
			page: getArchMessages.page, 
			last_page
		};

		return finalShipmentNotif;
	}
};

const actions = {
	getNotificationFromApi({ getters, dispatch, commit }, payload) {
		commit("SET_NOTIFICATIONS_IS_LOADING", true);
		let { id, page } = payload;
		// const id = getters.getCustomer.id;
		page = typeof page === "undefined" ? 1 : page;

		axios.get(`${APIBaseUrl}/notifications/${id}?per_page=20&page=${page}`)
		.then((res) => {
			let data = res.data.data;
			let currentActiveNotifications = getters.getMessages.notifications;
			let notificationArray = methods.processNotificationData(data, page);
			let combinedData = [ ...notificationArray, ...currentActiveNotifications ];

			combinedData = _.uniqBy(combinedData, 'id')
			if (combinedData.some(notification => notification.read_at === null)) {
				commit("SET_HAS_NEW", true)
			} else commit("SET_HAS_NEW", false)

			let finalData = { notifications: combinedData, page, last_page: res.data.last_page, copiedVal: res.data };
			commit("SET_MESSAGES", finalData);
			commit("SET_NOTIFICATIONS_IS_LOADING",false);
			dispatch("calculateUnreadMessages", data);
		})
		.catch((e) => {
			console.log(e);
		});
	},
	getArchivedMessages({ getters, commit }, payload) {
		// const id = getters.getCustomer.id;
		commit("SET_NOTIFICATIONS_IS_LOADING", true);
		let { id, page } = payload;
		page = typeof page === "undefined" ? 1 : page;

		axios.get(`${APIBaseUrl}/notifications/${id}?archived=1&per_page=20&page=${page}`)
		.then((res) => {
			let data = res.data.data;
			let currentArchivedNotifs = getters.getArchMessages.notifications;
			let archivedArray = methods.processNotificationData(data);
			let combinedData = res.data.data.length !== 0 ? [ ...archivedArray, ...currentArchivedNotifs ] : [];
			combinedData = _.uniqBy(combinedData, 'id');

			let finalData = { notifications: combinedData, page, last_page: res.data.last_page, copiedVal: res.data };
			commit("SET_ARCH_MESSAGES", finalData);
			commit("SET_NOTIFICATIONS_IS_LOADING", false);
		})
		.catch((e) => {
			console.log(e);
			commit("SET_NOTIFICATIONS_IS_LOADING",false);
		});
	},
	fetchPushedNotification: async ({commit, dispatch}, payload) => {
		commit('PUSH_SHOULD_PUSH_NOTIFY', payload.id);
		dispatch('getNotificationFromApi', 1);
        commit('SET_HAS_NEW', true);
    },
	markShipmentAsRead: async ({getters, commit}, payload) => {
        commit("SET_NOTIFICATION_GLOBAL_LOADING", true);

		let passedData = {
			method: "post",
			url: `${APIBaseUrl}/notifications/${payload.cid}/${payload.id}/read`,
		}

		return new Promise((resolve, reject) => {
            axios(passedData)
			.then(res => {
				if (res.status === 200) {
					let currentNotif = res.data.data;
					let { getMessages, getSortedMessages } = getters;
					let activeNotifications = getMessages.notifications;
					let sortedNotifications = getSortedMessages.notifications;
					let sortBy = typeof payload.sortBy !== "undefined" ? payload.sortBy.toLowerCase() : "all";

					// update active notifications list
					let findIndex = _.findIndex(activeNotifications, e => (e.id === currentNotif.id));
					activeNotifications[findIndex] = methods.processSingleData(currentNotif);
					let finalShipmentNotif = { 
						notifications: activeNotifications, page: getMessages.page, last_page: getMessages.last_page
					};
					commit("SET_MESSAGES", finalShipmentNotif);
					
					if (sortBy !== "all") {
						// if sorted notification, update data with response
						let findIndex = _.findIndex(sortedNotifications, e => (e.id === currentNotif.id));
						sortedNotifications[findIndex] = methods.processSingleData(currentNotif);
						let finalShipmentNotif = { 
							notifications: sortedNotifications, 
							page: getSortedMessages.page, 
							last_page: getSortedMessages.last_page,
							type: sortBy
						};
						commit("SET_SORTING_NOTIFICATIONS", finalShipmentNotif);
					}

					// let currentTab = payload.sortBy;

					// if (currentTab !== "all") {
					// 	let sortedMessages = getters.getSortedMessages.notifications;
					// 	let findPage = _.find(sortedMessages, e => (e.id === data.id));
					// 	if (findPage !== undefined) {
					// 		let payload = { 
					// 			page: findPage.page, 
					// 			types: [currentTab], 
					// 			isSorting: true, 
					// 			isScrolling: false 
					// 		}
					// 		dispatch("getSortedNotificationFromApi", payload);
					// 	}
					// 	let foundIndex = _.findIndex(sortedMessages, e => (e.id === data.id));
					// 	let payload = { index: foundIndex, newData: methods.processSingleData(data) }
					// 	commit("UPDATE_PARTICULAR_NOTIFICATION", payload)
					// } else {
					// 	dispatch("getNotificationFromApi", 1);
					// }
				}				
				commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
				resolve()
			})
			.catch((e) => {reject(e)})
			.finally(() => {
				commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
			})
        })
    },
	markAllShipmentAsRead: async ({getters, commit}, sortBy) => {
		const id = getters.getCustomer.id;
		commit("SET_NOTIFICATION_GLOBAL_LOADING", true);
		// notification_ids param - for future use if we decide to mark multiple notifications as read

		let passedData = {
			method: "post",
			url: `${APIBaseUrl}/notifications/${id}/read`,
		}

		sortBy = typeof sortBy !== "undefined" ? sortBy.toLowerCase() : "all";

		axios(passedData)
		.then((res) => {
			let { getMessages, getSortedMessages } = getters;
			let activeNotifications = getMessages.notifications;
			let sortedMessages = getSortedMessages.notifications;

			if (res.status === 200) {				
				let filterUnread = _.filter(activeNotifications, e => (e.read_at === null));
				filterUnread.map(v => {
					v.read_at = moment().format("YYYY-MM-DD hh:mm:ss");
				})

				if (sortBy !== "all") {
					let filterUnread = _.filter(sortedMessages, e => (e.read_at === null));
					filterUnread.map(v => {
						v.read_at = moment().format("YYYY-MM-DD hh:mm:ss");
					})
				}

				// dispatch("getNotificationFromApi", 1);
			}
			commit("SET_HAS_NEW", false);
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		})
		.catch((error) => {
			console.log(error);
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		});
    },
	archiveOrUnarchiveNotification: async ({ getters, commit }, payload) => {
		const cid = getters.getCustomer.id;
		commit("SET_NOTIFICATION_GLOBAL_LOADING", true);
		axios.post(`${APIBaseUrl}/notifications/${cid}/${payload.id}/${payload.isFor}`)
		.then((res) => {
			if (res.status === 200) {
				// let { getMessages, getArchMessages } = getters;
				// let currentNotif = res.data.data, 
				// 	currentNotifications = getMessages.notifications,
				// 	archivedNotifications = getArchMessages.notifications;

				// let filterNotifs = _.filter(currentNotifications, e => (e.id !== currentNotif.id));
				// let filterArcNotifs = _.filter(archivedNotifications, e => (e.id !== currentNotif.id));

				// let activeNotif = getMessages.copiedVal, aPage = getMessages.page;
				// let archivedNotif = getArchMessages.copiedVal, arPage = getArchMessages.page;

				// // active notifications page calculation
				// if (activeNotif !== null && activeNotif.data.length === 1 && activeNotif.current_page !== 1) {
				// 	aPage = aPage - 1;
				// 	getMessages.last_page = getMessages.last_page - 1;
				// }

				// // archived notifications page calculation
				// if (archivedNotif !== null && archivedNotif.data.length === 1 && archivedNotif.current_page !== 1) {
				// 	arPage = arPage - 1;
				// 	getArchMessages.last_page = getArchMessages.last_page - 1;
				// }
				
				// let finalShipmentNotif = { 
				// 	notifications: filterNotifs, page: aPage, last_page: getMessages.last_page
				// };
				// let finalArchNotif = { 
				// 	notifications: filterArcNotifs, page: arPage, last_page: getArchMessages.last_page
				// };

				// // save updated notification before calling API to update
				// commit("SET_MESSAGES", finalShipmentNotif);
				// commit("SET_ARCH_MESSAGES", finalArchNotif);

				// if (payload.isFor === "archive") {
				// 	dispatch("getNotificationFromApi", aPage);
				// 	dispatch("getArchivedMessages", 1);
				// } else {
				// 	dispatch("getArchivedMessages", arPage);
				// 	dispatch("getNotificationFromApi", 1);
				// }
				// if(payload.sortBy !== undefined && payload.sortBy !== 'undefined'){
				// 	if(payload.sortBy !== 'all'){
				// 		let sorted_payload = {page: 1, types: [payload.sortBy]}
				// 		dispatch("getSortedNotificationFromApi",sorted_payload)
				// 	}
				// }
				// console.log(payload)
				// working code on top

				let { getMessages, getArchMessages, getSortedMessages } = getters; // logic without calling API
				let activeNotifications = getMessages.notifications;
				let archiveNotifications = getArchMessages.notifications;
				let sortedMessages = getSortedMessages.notifications;
				let currentNotif = res.data.data;

				let sortBy = typeof payload.sortBy !== "undefined" ? payload.sortBy : "all";

				if (payload.isFor === "archive") {
					if (sortBy !== "all") { // if sorted
						// remove notification from list in sorted
						let findIndex = _.findIndex(sortedMessages, e => (e.id === currentNotif.id));
						sortedMessages[findIndex] = methods.processSingleData(currentNotif);
						let finalShipmentNotif = { 
							notifications: sortedMessages, 
							page: getSortedMessages.page, 
							last_page: getSortedMessages.last_page,
							type: sortBy
						};
						commit("SET_SORTING_NOTIFICATIONS", finalShipmentNotif);
					}

					// update notification to archived
					let activePayload = { activeNotifications, currentNotif, getMessages }
					let activeProcessData = methods.updateMessagesNotifications(activePayload);
					commit("SET_MESSAGES", activeProcessData);

					// update archive notification lists
					let archivePayload = { archiveNotifications, currentNotif, getArchMessages }
					let archiveProcessData = methods.updateArchivedNotifications(archivePayload);
					commit("SET_ARCH_MESSAGES", archiveProcessData);
				} else {
					// if unarchiving, remove the notification in the archived notification list
					let unarchivedPayload = { archiveNotifications, currentNotif, getArchMessages };
					let archiveProcessData = methods.spliceArchiveNotification(unarchivedPayload);
					commit("SET_ARCH_MESSAGES", archiveProcessData);

					// find index of unarchived notif and update active notification data
					let findIndexOfUpdatedNotif = _.findIndex(activeNotifications, e => (e.id === currentNotif.id));
					if (findIndexOfUpdatedNotif > -1) {						
						let payload = { index: findIndexOfUpdatedNotif, newData: methods.processSingleData(currentNotif) }
						commit("UPDATE_MESSAGES_NOTIFICATIONS", payload);
					}
				}
			}
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		})
		.catch((error) => {
			console.log(error);
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		});
	},
	unarchiveAllShipmentNotification: async ({getters, dispatch, commit}) => {
		const cid = getters.getCustomer.id;
		commit("SET_NOTIFICATION_GLOBAL_LOADING", true);
		// notification_ids param - for future use if we decide to mark multiple notifications as unarchive

		axios.post(`${APIBaseUrl}/notifications/${cid}/unarchive`)
		.then((res) => {
			// let { page } = getters;
			let { getMessages } = getters;
			let activeNotifications = getMessages.notifications;

			if (res.status === 200) {
				activeNotifications.map(v => {
					if (v.archived_at !== null) {
						v.archived_at = null;
					}
				})

				let finalShipmentNotif = { 
					notifications: activeNotifications, page: getMessages.page, last_page: getMessages.last_page
				};
				commit("SET_MESSAGES", finalShipmentNotif);
			}
			dispatch('getArchivedMessages', 1);
			commit("SET_HAS_NEW", false);
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		})
		.catch((error) => {
			console.log(error);
			commit("SET_NOTIFICATION_GLOBAL_LOADING", false);
		});
	},
	getSortedNotificationFromApi({ getters, dispatch, commit }, payload) {
		// dispatch("setFilteredNotificationsVal");
		commit("SET_SORTING_NOTIFICATIONS_LOADING", true);
		const id = getters.getCustomer.id;
		let page = typeof payload.page === "undefined" ? 1 : payload.page;
		// let isScrolling = typeof payload.isScrolling === "undefined" ? false : payload.isScrolling;
		let currentSortValue = payload.types[0];

		let passedData = {
			method: "get",
			url: `${APIBaseUrl}/notifications/${id}`,
			params: { page, per_page: 20 }
		}

		if (payload.types[0] !== "all") passedData.params.types = payload.types;

		axios(passedData)
		.then((res) => {
			let data = res.data.data;
			let notificationArray = methods.processNotificationData(data, page);
			let combinedData = [];

			// let currentActiveNotifications = getSortedMessages.notifications;
			// combinedData = [ ...notificationArray, ...currentActiveNotifications ];

			let { getSortedMessages } = getters;

			if (getSortedMessages.type === currentSortValue) {
				let currentActiveNotifications = getSortedMessages.notifications;
				combinedData = [ ...notificationArray, ...currentActiveNotifications ];
			} else {
				dispatch("setFilteredNotificationsVal");
				combinedData = notificationArray;
			}

			// if (isScrolling && !payload.isSorting) {
			// 	let currentActiveNotifications = getSortedMessages.notifications;
			// 	combinedData = [ ...notificationArray, ...currentActiveNotifications ];
			// } else {
			// 	dispatch("setFilteredNotificationsVal");
			// 	// let finalData = { notifications: [], page: 1, last_page: 1, copiedVal: null };
			// 	// commit("SET_MESSAGES", finalData);
			// 	combinedData = notificationArray;
			// }

			combinedData = _.uniqBy(combinedData, 'id');
			let finalData = { 
				notifications: combinedData, 
				page, 
				last_page: res.data.last_page, 
				copiedVal: res.data, 
				type: currentSortValue 
			};
			commit("SET_SORTING_NOTIFICATIONS", finalData);
			commit("SET_SORTING_NOTIFICATIONS_LOADING", false);
		})
		.catch((e) => {
			console.log(e);
		});
	},
	setFilteredNotificationsVal({ commit }) {
		let finalData = { notifications: [], page: 1, last_page: 1, copiedVal: null, type: "" };
		commit("SET_SORTING_NOTIFICATIONS", finalData)
	},

	// update custom notification
	addRecipients({ commit, dispatch }, payload) {
		commit("SET_LOADING", true);
		axios
			.post(`${APIBaseUrl}/v2/settings/email-recipients`, payload)
			.then((res) => {
				commit("SET_LOADING", false);
				commit("SET_CUSTOMER", res.data.data);
				dispatch("updateCustomerInApp", res.data.data);
				commit("SET_EMAIL_DAILOG", false);
			})
			.catch((e) => {
				commit("SET_LOADING", false);
				commit("SET_EMAIL_DAILOG", false);
				console.log(e);
			});
	},
	updateNotificationTime({ commit, dispatch }, payload) {
		// commit("SET_GENRAL_LOADER", true);
		commit("SET_CUSTOM_TIME_LOADING", true);
		axios
			.post(`${APIBaseUrl}/v2/settings/notification-time`, payload)
			.then((res) => {
				commit("SET_CUSTOMER", res.data.data);
				dispatch("updateCustomerInApp", res.data.data);
				commit("SET_GENRAL_LOADER", false);

				let payload = {
					show: false,
					ref: null,
					state: null,
					property: null,
				};
				commit("SET_BOX_OPEN", payload);
				commit("SET_CUSTOM_TIME_LOADING", false);
				commit("SET_CUSTOM_TIME_DAILOG", false);
			})
			.catch((e) => {
				commit("SET_CUSTOM_TIME_LOADING", false);
				console.log(e);
				commit("SET_CUSTOM_TIME_DAILOG", false);
				commit("SET_GENRAL_LOADER", false);
			});
	},
	updateEmailSettings({ commit, dispatch }, payload) {
		// commit("SET_GENRAL_LOADER", true);
		commit("SET_LOADING", true);
		axios
			.post(`${APIBaseUrl}/v2/settings/email-status`, payload)
			.then((res) => {
				commit("SET_CUSTOMER", res.data.data);
				dispatch("updateCustomerInApp", res.data.data);
				commit("SET_GENRAL_LOADER", false);
				commit("SET_LOADING", false);
				commit("SET_EMAIL_WARNING", false);
			})
			.catch((e) => {
				commit("SET_GENRAL_LOADER", false);
				commit("SET_LOADING", false);
				commit("SET_EMAIL_WARNING", false);
				console.log(e);
			});
	},
	updateCustomerInApp(context, val) {
		let getUser;
		let users = context.rootGetters["getUser"];
		if (typeof users === "string") {
			getUser = JSON.parse(users);
		} else {
			getUser = users;
		}
		let customerTwos = getUser.customers_api;
		if (customerTwos && customerTwos.length > 0) {
			let selectedCustomer = getUser.default_customer_id;
			_.remove(customerTwos, function(customer) {
				if (customer.id == selectedCustomer) {
					return customer;
				}
			});
			var other = _.concat(customerTwos, [val]);
			getUser.customers_api = other;
			getUser.default_customer = val;
			getUser.customer = val;
			let updated = JSON.stringify(getUser);
			localStorage.setItem("shifl.user_details", updated);
			context.commit("SET_USER", updated, { root: true });
		}
	},
	calculateUnreadMessages({ commit }, payload) {
		let count = 0;
		payload.forEach((item) => {
			if (item.read == 0) {
				count++;
			}
		});
		commit("SET_UNREAD_MESSAGES", count);
	},
	// connectToNotificationChannel(context) {
	// 	const id = context.getters.getCustomer.id;
	// 	window.centralEcho
	// 		.private(`customer.notification.${id}`)
	// 		.listen("PushNotification", (e) => {
	// 			context.commit("ADD_NEW_MESSAGE", e.message);
	// 			context.commit("ADD_NEW_UNREAD_MESSAGE");
	// 		});
	// 	window.centralEcho.channel("my").listen("PushNotification", (e) => {
	// 		console.log(e);
	// 	});
	// },
};

const mutations = {
	SET_MESSAGES: (state, payload) => {
		state.messages = payload;
	},
	SET_ARCH_MESSAGES: (state, payload) => {
		state.archMessages = payload;
	},
	SET_TEMP_CUSTOMER_ID: (state, payload) => {
		state.tempCustomerId = payload;
	},
	SET_TEMP_PERIOD: (state, payload) => {
		state.tempPeriod = payload;
	},
	ADD_NEW_UNREAD_MESSAGE: (state) => {
		let current = state.unreadMessages;
		state.unreadMessages = current + 1;
	},
	SET_CUSTOMER: (state, payload) => {
		state.customer = payload;
	},
	SET_UNREAD_MESSAGES: (state, payload) => {
		state.unreadMessages = payload;
	},
	SET_EMAIL_WARNING: (state, payload) => {
		state.emailWarning = payload;
	},
	SET_GENRAL_LOADER: (state, payload) => {
		state.genralLoader = payload;
	},

	SET_LOADING: (state, payload) => {
		state.loading = payload;
	},
	ADD_NEW_MESSAGE: (state, payload) => {
		let newData = methods.processSingleData(payload);

		let foundIndex = _.findIndex(state.messages.notifications, e => (e.id === newData.id));
		if (foundIndex === -1)		
			state.messages.notifications.push(newData);
		else
			state.messages.notifications[foundIndex] = newData;
	},
	SET_SORT_MESSAGES: (state, payload) => {
		state.sortMessages = payload;
	},
	SET_CURRENT_EDIT_ITEM: (state, payload) => {
		state.currentEditItem = payload;
	},
	SET_CUSTOM_TIME_DAILOG: (state, payload) => {
		state.customTimeDailog = payload;
	},
	SET_EMAIL_DAILOG: (state, payload) => {
		state.emailDailog = payload;
	},
	CURRENT_EMAIL_RECPIENTS: (state, payload) => {
		state.currentEmailRecpients = payload;
	},
	SET_BOX_OPEN: (state, payload) => {
		state.boxOpen = payload;
	},

	PUSH_SHOULD_PUSH_NOTIFY: (state, payload) => {
        state.shouldPushNotify.push(payload);
    },
    REMOVE_FROM_SHOULD_NOTIFY: (state, payload) => {
        state.shouldPushNotify = state.shouldPushNotify.filter(id => id !== payload);
    },
	SET_HAS_NEW: (state, payload) => {
        state.hasNew = payload;
    },
	SET_NOTIFICATIONS_IS_LOADING: (state, payload) => {
        state.notificationsIsLoading = payload;
    },
	SET_NOTIFICATION_GLOBAL_LOADING: (state, payload) => {
        state.notifGlobalLoading = payload;
    },
	SET_SORTING_NOTIFICATIONS: (state, payload) => {
        state.sortedMessages = payload;
    },
	SET_SORTING_NOTIFICATIONS_LOADING: (state, payload) => {
        state.sortedMessagesLoading = payload;
    },
	UPDATE_PARTICULAR_NOTIFICATION: (state, payload) => {
		state.messages.notifications[payload.index] = payload.newData;
	},
	UPDATE_MESSAGES_NOTIFICATIONS: (state, payload) => {
		state.messages.notifications[payload.index] = payload.newData;
	},
	UPDATE_ARCHIVED_NOTIFICATIONS: (state, payload) => {
		state.archMessages.notifications[payload.index] = payload.newData;
	},
	openDocumentPreview: (state, payload) => {
		state.shipmentDocumentPreview = payload;
	},
	SET_CUSTOM_TIME_LOADING: (state, payload) => {
		state.customTimeLoading = payload;
	},
	SET_OLD_CUSTOMER_ID: (state, payload) => {
		state.oldCustomerId = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};