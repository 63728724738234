/** @format */

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import shipment from './modules/shipment';
import page from './modules/page';
import shipmentDetails from './modules/shipmentDetails';
import masterSearch from './modules/masterSearch';
import scheduleOptions from './modules/scheduleOptions';
import milestones from './modules/milestones';
import salesOrders from './modules/salesOrders';
import products from './modules/products';
import category from './modules/category';
import warehouse from './modules/warehouse';
import inventory from './modules/inventory';
import suppliers from './modules/suppliers';
import statements from './modules/statements';
import inbound from './modules/inventories/inbound';
import outbound from './modules/inventories/outbound';
import locations from './modules/inventories/locations';
import invoice from './modules/invoice';
import paymentMethod from './modules/paymentMethod';
import users from './modules/users';
import storableUnit from './modules/inventories/storableUnit';
import reports from './modules/reports';
import productInventories from './modules/inventories/productInventories';
import trackingShipment from './modules/trackingShipment';
import documents from './modules/documents';
import customers from './modules/customers';
import notes from './modules/notes';
import shipmentProducts from './modules/shipmentProducts';
import warehouseCustomers from './modules/warehouseCustomers';
import accounting from './modules/accounting';
import paymentHistory from "./modules/paymentHistory";
import notifications from "@/store/modules/notifications";
import po from './modules/po';
import booking from './modules/booking';
import orders from "./modules/orders";
import poDetails from './modules/poDetails'
import drayage from "./modules/drayage";
import settings from "./modules/settings";
import bookingTemplate from "./modules/bookingTemplate";
import shipmentDetailsV2 from "./modules/shipmentDetailsV2";
import token from "./modules/token";
import shipmentSubTabs from "./modules/shipmentSubTabs";
import notificationNew from "./modules/notificationNew";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        shipment,
        page,
        shipmentDetails,
        masterSearch,
        scheduleOptions,
        milestones,
        salesOrders,
        products,
        category,
        warehouse,
        inventory,
        suppliers,
        statements,
        inbound,
        outbound,
        locations,
        invoice,
        paymentMethod,
        users,
        storableUnit,
        reports,
        productInventories,
        trackingShipment,
        documents,
        customers,
        notes,
        shipmentProducts,
        warehouseCustomers,
        accounting,
        paymentHistory,
        po,
        booking,
        orders,
        poDetails,
        notifications,
        drayage,
        settings,
        bookingTemplate,
        shipmentDetailsV2,
        token,
        shipmentSubTabs,
        notificationNew
    }
});
