import axios from "axios"
import store from ".."

const state = {
    statements: [],
    statementsLoading: false,
	periodicStatements: [],
	periodicStatementsLoading: false,
	reportSchedule: null,
	reportScheduleLoading: false,
	updateReportScheduleLoading: false
}

const getters = {
    getAchStatements: state => state.statements,
    getAchStatementsLoading: state => state.statementsLoading,
	getPeriodicStatements: state => state.periodicStatements,
	getPeriodicStatementsLoading: state => state.periodicStatementsLoading,
	getReportScheduleDetails: state => state.reportSchedule,
	getReportScheduleDetailsLoading: state => state.reportScheduleLoading,
	getUpdateReportSchedule: state => state.updateReportScheduleLoading,
}

// const poBaseUrl = "https://beta.shifl.com/api"

const actions = {
    fetchAchStatements: async ({
		commit, state
	}, page) => {
		commit("SET_STATEMENTS_LOADING", true)
		let passedData = {
            url: `/v2/statements?version=2`,
            method: 'GET',
			params: {				
				page,
				per_page: 15,
				paginate: true
			}
        }

		await axios(passedData)
		// await axios.get(`/statements`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_STATEMENTS_LOADING", false)
					
					let finalData = {
						statements: [],
						current_page: 1,
						total: 0,
						last_page: 0,
						old_page: 1,
						per_page: 15
					}

					let statementsData = res.data.data

					finalData = {
						current_page: statementsData.current_page,
						last_page: statementsData.last_page,
						statements: statementsData.data,
						total: statementsData.total,
						old_page: statementsData.current_page,
					}

					state.periodicStatements = [];
					commit("SET_STATEMENTS", finalData)
				}
			}
		})
		.catch(err => {
			commit("SET_STATEMENTS_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				if (typeof err.message !== 'undefined') {
					return Promise.reject('Token has been expired. Please reload the page.')
				}
			}
		})
	},
	fetchPeriodicStatements: async ({
		commit
	}) => {
		commit("SET_PERIODIC_STATEMENTS_LOADING", true)
		let passedData = {
            url: `/periodic-statement-schedule`,
            method: 'GET',
        }

		await axios(passedData)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_PERIODIC_STATEMENTS_LOADING", false)
					commit("SET_PERIODIC_STATEMENTS", res.data.data)
				}
			}
		})
		.catch(err => {
			commit("SET_PERIODIC_STATEMENTS_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				if (typeof err.message !== 'undefined') {
					return Promise.reject('Token has been expired. Please reload the page.')
				}
			}
		})
	},
	fetchReportSchedule: async ({
		commit
	}, id) => {
		commit("SET_REPORT_SCHEDULE_LOADING", true)
		let passedData = {
            // url: `/ach-details/${id}`,
			url: `/ach-settings/${id}`,
            method: 'GET',
        }

		await axios(passedData)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_REPORT_SCHEDULE_LOADING", false)
					commit("SET_REPORT_SCHEDULE", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_REPORT_SCHEDULE_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				if (typeof err.message !== 'undefined') {
					return Promise.reject('Token has been expired. Please reload the page.')
				}
			}
		})
	},
	updateReportSchedule: async ({
        commit
    }, payload) => {
        let attempt = false
        return new Promise((resolve, reject) => {
            function proceed() {
                commit("SET_UPDATE_REPORT_SCHEDULE_LOADING", true)

				let { id, ...otherPayload } = payload
				
				let passedData = {
					// url: `/ach-details/update/${id}`,
					url: `/ach-settings/update/${id}`,
					method: 'post',
					data: otherPayload
				}

                axios(passedData)
                .then(res => {
                    commit("SET_UPDATE_REPORT_SCHEDULE_LOADING", false)
                    if (typeof res !== 'undefined' && res.status === 200) {
                        resolve(res.data)
                    }
                })
                .catch(err => {
                    if (typeof err.message!=='undefined') {
                        if (!attempt) {
                            attempt = true
                            let t = setInterval(() => {
                                if (!store.getters.getIsRefreshing) {
                                    proceed()
                                    clearInterval(t)
                                }
                            }, 300)
                        } else {
                            commit('SET_UPDATE_REPORT_SCHEDULE_LOADING', false)
                            reject(err.message)
                            console.log('Error is: ', err);
                        }
                    } else {
                        commit('SET_UPDATE_REPORT_SCHEDULE_LOADING', false)
                        reject(err.error)
                    }
                })
            }
            proceed()
            
        })
    },
}

const mutations = {
    SET_STATEMENTS: (state, payload) => {
		state.statements = payload
	},
	SET_STATEMENTS_LOADING: (state, payload) => {
		state.statementsLoading = payload
	},
	SET_PERIODIC_STATEMENTS: (state, payload) => {
		state.periodicStatements = payload
	},
	SET_PERIODIC_STATEMENTS_LOADING: (state, payload) => {
		state.periodicStatementsLoading = payload
	},
	SET_REPORT_SCHEDULE: (state, payload) => {
		state.reportSchedule = payload
	},
	SET_REPORT_SCHEDULE_LOADING: (state, payload) => {
		state.reportScheduleLoading = payload
	},
	SET_UPDATE_REPORT_SCHEDULE_LOADING: (state, payload) => {
		state.updateReportScheduleLoading = payload
	},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
