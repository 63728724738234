import ShipmentDocumentModel from '../../models/ShipmentDocumentModel'
import axios from "axios"
import moment from "moment"
import store from '../'

const state = {
	create: {
		loading: false,
	},
	deleteMultipleLoading: false,
	update: {
		loading: false
	},
	editId: 0,
	editDocument: {},
	requestDocument: [],
	requestDocumentLoading: false
}

/* getters */
const getters = {
	getUpdateLoading: state => state.update.loading,
	getEditId: state => state.editId,
	getEditDocument: state => state.editDocument,
	getDeleteMultipleLoading: state => state.deleteMultipleLoading,
	getAllRequestDocuments: state => state.requestDocument,
	getAllRequestDocumentsLoading: state => state.requestDocumentLoading
}

/* constant urls */
const betaBaseUrl = process.env.VUE_APP_BASE_URL

/* actions */
const actions = {
	setEditId: async({
		commit
	}, id) => {
		commit('SET_EDIT_ID', id)
	},
	setEditDocument: async({
		commit
	}, payload) => {
		commit('SET_EDIT_DOCUMENT', payload)
	},
	setDeleteMultipleLoading: ({
		commit
	}, payload) => {
		commit('SET_DELETE_LOADING', payload)
	},
	deleteDocuments: async({
		commit
	}, payload) => {
		return new Promise((resolve, reject) => {
			function proceed () {
				commit('SET_DELETE_MULTIPLE_LOADING', true)
				let shipmentDocumentModel = new ShipmentDocumentModel(betaBaseUrl)
				shipmentDocumentModel.deleteDocuments(payload).then(() => {
					commit('SET_DELETE_MULTIPLE_LOADING', false)
					resolve()
				}).catch( err => {
					commit('SET_DELETE_MULTIPLE_LOADING', false)
					reject(err)
				})
			}
			proceed()
		})
	},
	updateNameType: async ({
		commit
	}, payload) => {
		return new Promise((resolve, reject) => {
			function proceed () {
				commit('SET_UPDATE_LOADING', true)
				let shipmentDocumentModel = new ShipmentDocumentModel(betaBaseUrl)

				shipmentDocumentModel.updateNameType(payload).then(() => {
					commit('SET_UPDATE_LOADING', false)
					resolve()
				}).catch( err => {
					commit('SET_UPDATE_LOADING', false)
					reject(err)
				})
			}
			proceed()
		})
	},
	fetchRequestDocumentsAPI: async ({
		commit
	}, payload) => {
		let attempt = false
		commit("SET_REQUEST_DOCUMENT_LOADING", true)

		return new Promise((resolve, reject) => {
			function proceed() {
				axios.get(payload)
				.then(res => {
                    if (typeof res.status !== 'undefined') {
                        if (res.status === 200) {
                            if (res.data) {
                                let results = res.data.results  
                                commit("SET_REQUEST_DOCUMENT_LOADING", false)

								let docResults = []
								results.map(v => {
									v.emails = typeof v.emails === "string" ? JSON.parse(v.emails) : v.emails
									v.document_types = typeof v.document_types === "string" ? JSON.parse(v.document_types) : v.document_types
									v.requested_at = moment(v.created_at).format("hh:mm A M/DD/YY")

									docResults.push(v)
								})

                                commit("SET_REQUEST_DOCUMENT", docResults)
                                resolve()
                            }
                        }
                    }
				})
				.catch(err => {
					if (typeof err.message!=='undefined') {
						if ( !attempt ){
							attempt = true
							let t = setInterval(() => {
								if ( !store.getters.getIsRefreshing ) {
									proceed()
									clearInterval(t)
								}
							},300)
						} else {
							commit('SET_REQUEST_DOCUMENT_LOADING', false)
							reject(err)
						}
					}

					if (typeof err.error !=='undefined') {
						commit('SET_REQUEST_DOCUMENT_LOADING', false)
						reject(err.error)
					}
				})	
			}
			proceed()
		})
	},
}
const mutations = {
	SET_UPDATE_LOADING: (state, payload) => {
		state.update.loading = payload	
	},
	SET_EDIT_ID: (state, payload) => {
		state.editId = payload	
	},
	SET_DELETE_MULTIPLE_LOADING: (state, payload) => {
		state.deleteMultipleLoading = payload
	},
	SET_EDIT_DOCUMENT: (state, payload) => {
		state.editDocument = payload	
	},
	SET_REQUEST_DOCUMENT: (state, payload) => {
		state.requestDocument = payload	
	},
	SET_REQUEST_DOCUMENT_LOADING: (state, payload) => {
		state.requestDocumentLoading = payload	
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}