<template>
	<div class="test">
		<v-list-item-content class="base-notification-content pl-6">
			<v-list-item-title class="mb-1">				
				<v-tooltip bottom content-class="notification-tooltip-settings tooltip-bottom">
					<template v-slot:activator="{ on }">
						<span v-if="!hasBeenRead">
							<v-icon color="#0171A1" size="12px" @click.stop="markNotificationAsRead" v-on="on" class="notification-dot">
								mdi-circle
							</v-icon>
						</span>					
					</template>

					<span>Mark as Read</span>
				</v-tooltip>
			
				<span style="color: #253041;" class="font-medium ml-1">
					<span class="font-medium" v-if="title !== ' Shipment Notification'">{{ title }}</span>
					
					<!-- Shipment Notification titles -->
					<span v-else class="font-medium">
						<span v-if="notification.data.type === 'booking_created'" class="font-medium">
							A Booking <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>  has been created
						</span>

						<span v-if="notification.data.type === 'booking_confirmed'" class="font-medium">
							Booking <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been confirmed
						</span>					

						<span v-if="notification.data.type === 'booking_updated'" class="font-medium">
							Booking <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been updated
						</span>

						<span v-if="notification.data.type === 'document_upload'" class="font-medium">
							<!-- @click.stop="viewShipment(notification.data)" -->
							Document of {{notification.data.data.type}} Booking <span class="shipment-item-heading">
								
								#{{notification.data.data.shifl_ref}}
							</span> has been uploaded
						</span>		

						<span v-if="notification.data.type === 'departure_notice_sent'" class="font-medium">
							A Departure Notice for Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been issued
						</span>

						<span v-if="notification.data.type === 'arrival_notice_sent'" class="font-medium">
							An Arrival Notice for Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been issued
						</span>

						<span v-if="notification.data.type === 'delivery_order_sent'" class="font-medium">
							A Delivery Order for Container <span class="shipment-item-heading">
								#{{notification.data.container_numbers}} </span> in Shipment
							<span class="shipment-item-heading">#{{notification.data.data.shifl_ref}}</span> has been issued
						</span>

						<span v-if="notification.data.type === 'eta_changed' || notification.data.type === 'eta_change_alert'" class="font-medium">
							There was an ETA Change to Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>
						</span>

						<span v-if="notification.data.type === 'booking_request'" class="font-medium">
							A new booking request <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been issued
						</span>

						<span v-if="notification.data.type === 'consolidation_request'" class="font-medium">
							A new booking consolidation request <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span> has been issued
						</span>

						<span v-if="notification.data.type === 'etd_change_alert'" class="font-medium">
							There was an ETD Change to Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>
						</span>

						<span v-if="notification.data.type === 'lfd_alert'" class="font-medium">
							An LFD Alert for Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>
						</span>

						<span v-if="notification.data.type === 'discharge_alert'" class="font-medium">
							A Container Discharge Alert for Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>
						</span>

						<span v-if="notification.data.type === 'unreturned_container_alert'" class="font-medium">
							Unreturned Container Alert for Shipment <span class="shipment-item-heading">
								#{{notification.data.data.shifl_ref}}
							</span>
						</span>
					</span> 
				</span>
			</v-list-item-title>
			
			<v-list-item-subtitle style="color: #69758C;" class="font-regular ml-1">
				<div class="d-flex justify-space-between align-center">
					<span class="font-regular"> {{ subTitle }}</span>

					<v-tooltip left content-class="notification-tooltip-settings tooltip-left" v-if="title === ' Shipment Notification' && !isMobile">
						<template v-slot:activator="{ on }">
							<button class="archive-notification d-flex align-center" @click.stop="archiveNotif">
								<img src="@/assets/icons/archive.png" alt="archive" width="16px" height="16px" v-on="on">
							</button>				
						</template>

						<span>{{ !isShowArchived ? 'Archive' : 'Unarchive' }}</span>
					</v-tooltip>
				</div>
			</v-list-item-subtitle>
		</v-list-item-content>
	</div>
</template>

<script>
import { mapActions,mapMutations } from "vuex";
import moment from 'moment';

export default {
	name: "BaseNotification",
	data() {
		return {
			icon: "mdi-bell",
		};
	},
	props: {
		notification: {
			required: true,
		},
		isShowArchived: Boolean,
		isMobile: Boolean,
		hasNewNotifications: Boolean,
		sortBy: String
	},
	computed: {
		hasBeenRead() {
			return !!this.notification.read_at;
		},
		title() {
			return this.notification.type
				.split("\\")
				.pop()
				.replace(/([A-Z])/g, " $1");
		},
		subTitle() {
			// return this.notification.data.message ?? "";
			return this.formatTime(this.notification.created_at);
		},
		shipmentNotifTitle() {
			let notif = this.notification.data, title = "";
			if (notif.type === "booking_created") {
				title = "New Booking";
			} else if (notif.type === "booking_updated") {
				title = "Booking Update";
			} else if (notif.type === "booking_confirmed") {
				title = "Booking Confirmation";
			} else if (notif.type === "delivery_order_sent") {
				title = "Delivery Order";
			} else if (notif.type === "eta_changed" || notif.type === "eta_change_alert") {
				title = "ETA Change Alert";
			} else if (notif.type === "document_upload") {
				title = "New Document Upload";
			} else if (notif.type === "booking_request") {
				title = "Booking Request";
			} else if (notif.type === "consolidation_request") {
				title = "Consolidation Request";
			} else if (notif.type === "etd_change_alert") {
				title = "ETD Change Alert";
			} else if (notif.type === "lfd_alert") {
				title = "LFD Alert";
			} else if (notif.type === "unreturned_container_alert") {
				title = "Unreturned Container Alert";
			} else if (notif.type === "discharge_alert") {
				title = "Container Discharge Alert";
			} else {
				title = notif.type === 'departure_notice_sent' ? "Departure Notice" : "Arrival Notice"
			}
			return title;
		},
		shipmentNotifBody() {
			let notif = this.notification.data, subtitle = "", 
				pos = notif.data.po_nums, cont = notif.data.container_numbers;

			if (notif.type === "booking_created") {
				subtitle = `A new booking ID#${notif.data.shifl_ref} was issued ${pos !== null ? `for PO${pos}` : ''}`

			} else if (notif.type === "booking_updated") {
				subtitle = `Booking ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} was updated`

			} else if (notif.type === "booking_confirmed") {
				subtitle = `Booking ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} was confirmed`

			} else if (notif.type === "delivery_order_sent") {
				subtitle = `Delivery order for ID#${notif.data.shifl_ref} | PO#${pos} | Container#${cont} was issued`

			} else if (notif.type === "eta_changed") {
				subtitle = `ETA for ID#${notif.data.shifl_ref} - PO#${pos} was updated to ${this.formatDate(notif.data.new_eta)}`

			} else if (notif.type === "document_upload") {
				subtitle = `Shipment ID#${notif.data.shifl_ref} has new document upload`

			} else if (notif.type === "booking_request") {
				subtitle = `A new booking request with Shipment ID#${notif.data.shifl_ref} has been issued`;

			} else if (notif.type === "consolidation_request") {
				subtitle = `A new booking consolidation request with Shipment ID#${notif.data.shifl_ref} has been issued`;

			} else if (notif.type === "etd_change_alert") {
				subtitle = `ETD for ID#${notif.data.shifl_ref} - PO#${pos} was updated to ${this.formatDate(notif.data.new)}`

			} else if (notif.type === "lfd_alert") {
				subtitle = `LFD Alert for Shipment ID#${notif.data.shifl_ref}`
				
			} else if (notif.type === "discharge_alert") {
				subtitle = `Container Discharge Alert for Shipment ID#${notif.data.shifl_ref}`

			} else if (notif.type === "unreturned_container_alert") {
				subtitle = `Unreturned Container Alert for Shipment ID#${notif.data.shifl_ref}`

			} else {
				subtitle = notif.type === 'departure_notice_sent' ? 
					`Shipment ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} Departed on ${this.formatDate(notif.data.etd)}` : 
					`Shipment ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} will arrive on ${this.formatDate(notif.data.eta)}`
			}
			return subtitle;
		},
		getShipmentId(){
			return this.notification.data.data.shipment_id;
		}
	},
	methods: {
		...mapActions({
			markAsRead: "notifications/markAsRead",
			markShipmentAsRead: "notificationNew/markShipmentAsRead",
			archiveOrUnarchiveNotification: "notificationNew/archiveOrUnarchiveNotification",
		}),
		...mapMutations({
			openDocumentPreview:'notificationNew/openDocumentPreview'
		}),
		async markNotificationAsRead() {
			if (this.title !== ' Shipment Notification') {
				// await this.markAsRead(this.notification); - for PO notification mark as read
			} else {
				let data = {
					cid: this.notification.data.customer_id,
					id: this.notification.id,
					sortBy: this.sortBy
				}
				await this.markShipmentAsRead(data);
			}
		},
		async action() {
			if (!this.hasBeenRead) await this.markNotificationAsRead();
			if (typeof this.onOpen === "function") this.onOpen();
		},
		async goToRoute(route) {
			if (route === this.$router.currentRoute.fullPath) return false;
			await this.$router.push(route);
		},
		pushNotify() {
			const pushed = this.$store.getters["notifications/getShouldPushNotify"].includes(this.notification.id);
			if (pushed) {
				this.$store.commit("notifications/REMOVE_FROM_SHOULD_NOTIFY", this.notification.id);
			}
			if (Notification.permission === "granted" && pushed) {
				const desktopNotification = new Notification(this.title, {
					body: this.subTitle ?? "You have a new notification",
					icon: "/favicon-shifl.ico",
				});
				desktopNotification.onclick = this.action;
			}
		},
		pushNotifyShipments() {
			const sPush = this.$store.getters["notificationNew/getShouldPushNotify"].includes(this.notification.id);			
			if (sPush) {
				this.$store.commit("notificationNew/REMOVE_FROM_SHOULD_NOTIFY", this.notification.id);
			}
			if (Notification.permission === "granted" && sPush) {
				const desktopNotification = new Notification(this.shipmentNotifTitle, {
					body: this.shipmentNotifBody ?? "You have a new notification",
					icon: "/favicon-shifl.ico",
				});
				desktopNotification.onclick = this.action;
			}
		},		
		formatTime(time) {
			moment.updateLocale('en-gb', {
				relativeTime: {
					future: "in %s",
					past: "%s",
					s: "Today",
					ss: "Today",
					m: "Today",
					mm: "Today",
					h: "Today",
					hh: "Today",
					d: "Yesterday",
					dd: "%dd ago",
					M: "a month ago",
					MM: "%d months ago",
					y: "a year ago",
					yy: "%dyears ago",
				}
			});

			let formattedDate;
			if (time !== null) {
				formattedDate = moment(time).fromNow(); 
			}
			return formattedDate;
		},
		async archiveNotif() {
			let convertedString = undefined
			if(this.sortBy !== undefined && this.sortBy !== 'undefined'){
				convertedString = this.sortBy.toLowerCase().replace(/\s/g, '_');
			}else{
				convertedString = undefined
			}
			let data = { id: this.notification.id, isFor: "", sortBy:convertedString};
			data.isFor = !this.isShowArchived ? "archive" : "unarchive";
			await this.archiveOrUnarchiveNotification(data);
		},
		formatDate(date) {
			return date !== null ? moment(date).format("MM/DD/YYYY") : "";
		}
	},
	watch: {},
	created() {
		// this.pushNotifyShipments();
		// this.pushNotify();
	},
};
</script>

<style lang="scss">
.base-notification-content {
	position: relative;
	.shipment-item-heading {
		color: $shifl-primary-b90 !important;
		font-family: "Inter-SemiBold", sans-serif !important;
	}
	.notification-dot {
		position: absolute;
		left: 8px;
		padding-top: 3px;
	}
}
</style>
