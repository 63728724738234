<!-- @format -->

<template>
    <v-container class="notification-container">
        <special-dialog
            :title="specialNotificationTitle"
            :message="specialNotificationMessage"
            :show="getSpecialDialog"
            @close="closeSpecialDialog"
        />
        <!-- <v-row justify="end align-center"> -->
        <v-row
            :class="
                !isMobile ? 'row-desktop account-side-main-container' : 'row-mobile'
            "
        >
            <!-- <v-badge :content="messages" :value="messages" color="green" overlap>
                <img src="@/assets/images/notification-icon.svg" alt="" width="18" height="18" v-if="!isMobile">   
                <img src="@/assets/images/mobile-notif.png" alt="" v-if="isMobile" class="mobile-notif" />
            </v-badge> -->

            <v-menu
                :content-class="`account-side-main-menu-container ${customers.length > 0 ? '' : 'no-customers-container'}`"
                offset-y
                v-if="!isMobile"
                ref="parentMenuRef">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" class="account-side-btn" @click="onClickMenuAccount"
                        :class="attrs['aria-expanded'] == 'true' ? 'active' : ''">
                        <!--<v-avatar v-if="!isMobile" size="35">
                            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                            <v-icon small class="mr-2"> mdi-account </v-icon>
                        </v-avatar> -->
                        <div v-if="!isMobile" class="account-side-container">
                            <div class="d-flex align-center">
                                <div class="account-side-avatar mr-2">
                                    <!-- <img src="../assets/images/account-menus/avatar.png" /> -->
                                    <img src="@/assets/icons/import-name-logo.svg" />
                                </div>
                                <div class="account-side-names">
                                    <div class="account-side-name">
                                        {{ (getLoadingUserDetails) ? '' : defaultCustomerEntity.name }}
                                    </div>
                                    <div class="account-side-undername">
                                        {{
                                            (!getLoadingUserDetails) ? 
                                            defaultCustomerEntity.address == "" ||
                                            defaultCustomerEntity.address == null
                                                ? "No address found."
                                                : defaultCustomerEntity.address
                                            : ''
                                        }}
                                    </div>
                                    <!-- <div class="account-side-undername">
                                        <input type="hidden" id="testing-code" :value="(!getLoadingUserDetails && defaultCustomerEntity.company_key)? defaultCustomerEntity.company_key : ''">
                                        {{ companyKey }}
                                        <span 
                                            style="z-index: 99999999999; vertical-align: middle;"
                                            @click.stop.prevent="copyTestingCode"
                                            v-if="!getLoadingUserDetails && defaultCustomerEntity.company_key"
                                            >
                                            <img width="15px" src="../assets/icons/copy-to-clipboard.svg" />
                                        </span>
                                    </div> -->
                                </div>
                            </div>
                            <div
                                style="display: flex; justify-content: center; align-items: center; padding-left: 4px;"
                            >
                                <v-icon
                                    v-if="attrs['aria-expanded'] == 'true'"
                                    small
                                    color="#6D858F"
                                    >mdi-chevron-up</v-icon
                                >
                                <v-icon
                                    v-if="attrs['aria-expanded'] == 'false'"
                                    small
                                    color="#6D858F"
                                    >mdi-chevron-down</v-icon
                                >
                            </div>
                        </div>
                    </v-btn>
                </template>

                <v-card class="mx-auto account-side-card" min-width="240">
                    <v-card-title class="account-side-content-item-title" v-if="customers.length > 0" :class="isInputExpandedNew ? 'expanded-title' : ''">
                        <div class="d-flex justify-space-between align-center" style="width: 100%; height: 36px;">
                            <p class="mb-0" v-if="!isInputExpandedNew">Accounts</p>

                            <div class="search-accounts-wrapper" :class="isInputExpandedNew ? 'wrapper-expanded' : ''">
                                <input
                                    id="search-input-accounts"
                                    ref="refInput"
                                    v-model.trim="searchData"
                                    class="search-account-input"
                                    :class="isInputExpandedNew ? 'expanded': ''"
                                    type="text"
                                    :placeholder="isInputExpandedNew ? 'Search' : ''"
                                    autocomplete="off"
                                    @click.stop.prevent="$refs.parentMenuRef.isActive = true"
                                    v-if="isInputExpandedNew" />
                                    <!-- @input="handleSearch" -->
                                
                                <button class="close-btn" @click.stop.prevent="clearInput" v-if="isInputExpandedNew">
                                    <img src="@/assets/images/close.svg" alt="" width="18px" />
                                </button>

                                <div class="icon" @click.stop.prevent="inputClick">
                                    <img width="22px" height="22px"
                                        class="search-icon"
                                        src="@/assets/icons/search-icon-gray.svg"
                                        alt="" />
                                </div>
                            </div>
                        </div>
                    </v-card-title>

                    <!-- <div class="item-separator" v-if="customers.length > 0"></div> -->

                    <v-list class="account-side-list-container pt-0" :class="customers.length > 0 ? '' : 'no-customers'">
                        <!-- <div></div> -->

                        <!-- <v-list-item-title class="account-side-content-item-title" v-if="customers.length > 0">
                            Accounts
                        </v-list-item-title> -->

                        <v-list-item
                            @click="selectCustomer(customer)"
                            :key="key"
                            v-for="(customer, key) in filteredCustomers"
                            :class="
                                `account-side-list-items ${
                                    customer.is_selected == 1
                                        ? 'account-side-list-items-selected'
                                        : ''
                                }`
                            "
                        >
                            <div class="account-side-avatar" style="background-color: #fff !important;">
                                <!-- <img src="../assets/images/account-menus/avatar.png" /> -->
                                <img src="@/assets/icons/import-name-logo.svg" />
                            </div>
                            <v-list-item-content>
                                <v-list-item-title class="account-side-list-item-title">
                                    <div class="account-customer-name" :class="customer.is_selected == 1 ? 'current-selected-customer' : ''">
                                        {{ customer.name }}
                                    </div>
                                    <!-- <div
                                        class="current-selected"
                                        v-if="customer.is_selected == 1"
                                    >
                                        (Current)
                                    </div> -->
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ customer.company_name }}
                                </v-list-item-subtitle>
                                <!-- <v-list-item-subtitle style="padding-left: 2px;">
                                    Key: {{ customer.company_key }}
                                </v-list-item-subtitle> -->
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item class="no-customers justify-center" style="font-size: 14px;"
                            v-if="filteredCustomers.length === 0">
                            <div class="no-results-found">
                                <div class="no-matched d-flex align-center justify-center">
                                    <img width="18px" height="18px"
                                        class="search-icon"
                                        src="@/assets/icons/search-icon-gray.svg"
                                        alt="" />
                                </div>
                                <p class="font-semi-bold mb-1 p-title">No matching account is found</p>
                                <p class="font-medium mb-3 p-subtitle">Please check the spelling or try a different keyword</p>
                            </div>
                        </v-list-item>

                        <!-- <v-list-item class="logout-item-container" @click="userLogout">
                            <v-list-item-content>
                                <v-list-item-title class="account-side-menu-items logout-item">
                                    Log out
                                </v-list-item-title>
                            </v-list-item-content>
                            <div class="d-flex align-items-center">
                                <img src="../assets/images/account-menus/signout.svg" alt="Sign Out" width="18px" height="18px">
                            </div>
                        </v-list-item> -->
                    </v-list>

                    <div class="item-separator" v-if="customers.length > 0"></div>

                    <v-card-actions class="logout-item-container px-4">
                        <button class="btn-logout-user d-flex justify-space-between align-center" style="width: 100%;" @click="userLogout">
                            <span class="logout-item">Log Out</span>

                            <div class="d-flex align-items-center">
                                <img src="../assets/images/account-menus/signout.svg" alt="Sign Out" width="18px" height="18px">
                            </div>
                        </button>                        
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-row>
		<v-snackbar v-model="snackbarFlag" :timeout="snackbarTimeout">
			Company key copied to clipboard.
		</v-snackbar>
    </v-container>
</template>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
.account-side-list-container::-webkit-scrollbar {
    width: 6px;
}
    
/* Track */
.account-side-list-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
    
/* Handle */
.account-side-list-container::-webkit-scrollbar-thumb {
    background: #e2e2e2; 
    border-radius: 4px;
}
    
/* Handle on hover */
.account-side-list-container::-webkit-scrollbar-thumb:hover {
    background: #e2e2e2; 
}
.account-side-list-container {
    padding: 4px;
    max-height: 390px;
    /* overflow-y: scroll; */
    overflow-y: auto;
}
.account-side-list-container.no-customers {
    padding-top: 0 !important;
}
.account-side-list-item-title {
    display: flex;
}
.account-side-list-item-title > div.current-selected {
    padding-left: 8px;
    color: #1A6D9E;
    /* font-weight: 600; */
    font-family: "Inter-Medium", sans-serif;
}
.account-side-list-item-title .account-customer-name {
    font-family: "Inter-SemiBold", sans-serif;
    color: #253041 !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
/* .account-side-list-item-title .account-customer-name.current-selected-customer {
    font-family: "Inter-Medium", sans-serif;
} */
.v-list-item__subtitle {
    font-family: "Inter-Regular", sans-serif;
    color: #69758C !important;
}
.account-side-btn {
    background-color: #ffffff !important;
    filter: none;
    box-shadow: none;
    border-radius: 4px;
    padding: 8px !important;
    height: auto !important;
    border: 1px solid #FFF !important;
    width: 216px;
    max-width: 216px;
}
.account-side-btn.active {
    background-color: #F0F9FF !important;
    border: 1px solid #F3F4F7 !important;
}
.account-side-btn:before {
    display: none;
}
.account-side-btn:hover {
    background-color: #F0F9FF !important;
    border: 1px solid #F3F4F7 !important;
}
.item-separator {
    background-color: #E7E9EE;
    height: 1px;
    width: 96%;
    min-height: 1px;
    margin: 0 auto;
}
.account-side-main-menu-container {
    /* border-radius: 0px !important; */
    border-radius: 4px !important;
    background-color: transparent !important;
    background: transparent !important;
    left: inherit !important;
    right: 16px !important;
    /* min-width: 22% !important; */
    min-width: 280px !important;
    width: 280px;
    margin-top: 4px;
}
.account-side-main-menu-container.no-customers-container {
    width: 240px !important;
    min-width: unset !important;
}
.account-side-content-item-title {
    color: #69758C;
    font-family: "Inter-Regular", sans-serif;
    font-size: 14px;
    padding: 6px 16px;
}
.account-side-content-item-title.expanded-title {
    padding: 6px 8px;
}
.account-side-card {
    border-radius: 0px !important;
}
.account-side-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}
.account-side-container i.mdi-chevron-up, 
.account-side-container i.mdi-chevron-down {
    font-size: 20px !important;
}
.account-side-avatar img {
    /* width: 32px; */
    width: 16px;
    height: auto;
}
.account-side-avatar {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #D8E7F0;
}
.account-side-names {
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
    max-width: 130px;
}
.account-side-menu-items {
    color: #253041;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
}
.account-side-names > div {
    width: 100%;
    font-family: "Inter", sans-serif;
    text-align: left;
}
.account-side-names .account-side-name {
    font-size: 14px;
    color: #253041;
    font-weight: 600;
    text-transform: none;
    /* max-width: 160px; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.account-side-names .account-side-undername {
    color: #69758C;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
    text-align: left;
    /* max-width: 100px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 160px;
    padding-top: 2px;
}
.account-side-list-items {
    padding-left: 16px;
    cursor: pointer;
    border-radius: 4px;
}
.account-side-list-items:hover,
.account-side-main-items:hover,
.logout-item-container:hover {
    background: #f1f6fa;
}
.account-side-list-items .v-list-item__content {
    padding: 9px 0;
}
.account-side-list-items-selected {
    background: #F0F9FF;
    border-radius: 4px;
}
.logout-item-container,
.account-side-main-items {
    cursor: pointer;
}
.logout-item-container {
    min-height: 40px;
}
.row-desktop {
    justify-content: flex-end;
    align-items: center;
}
.mobile-notif {
    margin-top: 4px;
    width: 18px;
}
.avatar-button {
    padding: 0 !important;
    min-width: 35px !important;
    width: 35px !important;
    border-radius: 50% !important;
    margin: 0 5px 0 25px !important;
    background-color: #fff !important;
}
.btn-logout {
    background-color: transparent !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    letter-spacing: 0;
    font-weight: 600;
}
.avatar-button .v-btn__content .v-avatar i {
    font-size: 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
}
.loading-special {
    height: 32px;
    width: 32px;
    position: absolute;
    z-index: 1001;
    left: 50%;
    top: 50%;
}
.logout-item {
    /* color: #B11B1B; */
    color: #253041;
    font-family: 'Inter-Medium', sans-serif;
    font-size: 14px;
}
.logout-item-icon {
    background-image: url("../assets/images/account-menus/logout.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 18px;
    height: 13px;
}
/* .account-side-main-container {
    padding-right: 8px !important;
} */
</style>

<style lang="scss">
.search-accounts-wrapper {
    position: relative;
    height: 36px;
    width: 68%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.wrapper-expanded {        
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 8px 0 28px;
        background-color: #F9FAFB;
        border-radius: 4px;

        .icon {
            position: absolute;
            left: 5px;
            width: 22px;
        }

        .close-btn {
            display: flex;
            align-items: center;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        justify-content: flex-end;
        height: 100%;
    }

    .search-account-input {
        width: 100%;
        padding: 5px;
        transition: all .5s ease;
        box-sizing: border-box;
        opacity: 0;
        outline: 0;
        caret-color: #1A6D9E;

        &.expanded {
            width: 100%;
            opacity: 1;
            height: 36px;
        }
    }
}

.account-side-list-container{
    .no-customers {
        .no-matched {
            width: 32px;
            height: 32px;
            margin: 8px auto;
            border: 1px solid #E7E9EE;
            border-radius: 50%;
        }

        p {
            text-align: center;

            &.p-title {
                font-size: 14px;
                color: $shifl-neutral-n90;
            }

            &.p-subtitle {
                font-size: 12px;
                color: $shifl-neutral-n60;
                line-height: 16px;
                padding: 0 16px;
            }
        }
    }
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import SpecialDialog from "./Dialog/SpecialDialog";
import _ from "lodash";
export default {
    name: "Notification",
    props: ["isMobile"],
    components: {
        SpecialDialog,
    },
    data() {
        return {
            specialNotificationTitle: "Special Notification",
            specialNotificationMessage:
                "Refreshing the page in 5 seconds in order to update changes in your account.",
            messages: 0,
            items: [{ title: "Logout" }],
            snackbarTimeout: 5000,
            snackbarFlag: false,
            isInputExpandedNew: false,
            searchData: "",
            isShowSearchView: false,
            isOpen: false,
            customersListsCopy: []
        };
    },
    methods: {
        //
        ...mapActions(["logout", "updateCustomerPreference", "closeSpecialDialog"]),
        userLogout() {
            this.$store.dispatch("page/setTab", 1);
            this.logout();
        },
        selectCustomer({ id: customer_id }) {
            let userDetails = JSON.parse(localStorage.getItem("shifl.user_details"))
            if ( customer_id !== userDetails.default_customer_id ) {
                if (typeof userDetails === "object") {
                    localStorage.setItem(
                        "shifl.user_details",
                        JSON.stringify({
                            ...userDetails,
                            default_customer_id: customer_id,
                        })
                    );
                }
                this.updateCustomerPreference(customer_id)
                    .then((response) => {
                        if (typeof response.status !== "undefined") {
                            if (response.status == "ok") {
                                let currentUrl = window.location.pathname;
                                if (this.$route.name === 'PO Details') {
                                    window.location.href = '/pos';
                                } else if (this.$route.name === 'Shipment Details') {
                                    window.location.href = '/shipments';
                                } else if (this.$route.name === 'Inventory Inbound View' || 
                                    this.$route.name ==='Inventory Outbound View') {
                                    window.location.href = '/inventory?tab=Products';
                                } else if (this.$route.name === 'AddUserGroup') {
                                    window.location.href = '/settings?tab=users';
                                } else {
                                    window.location.href = currentUrl;
                                }
                            }
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });    
            }
            
        },
        copyTestingCode () {
            let testingCodeToCopy = document.querySelector('#testing-code');
            testingCodeToCopy.setAttribute('type', 'text');
            testingCodeToCopy.select();
            if(document.execCommand('copy')){
                this.snackbarFlag = true;
            }
            
            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        inputClick() {
			this.isInputExpandedNew = true;
			if (this.isInputExpandedNew && this.searchData == "") {
				this.isShowSearchView = true;
			}
            this.$refs.parentMenuRef.isActive = true;
            setTimeout(() => {
                document.getElementById("search-input-accounts").focus();
            }, 10);
		},
        clearInput() {
            this.$refs.parentMenuRef.isActive = true;
            if (this.searchData !== "") {                
                this.searchData = "";
                document.getElementById("search-input-accounts").focus();
            } else {
                this.isInputExpandedNew = false;
            }
		},
        includeNew() {
			return [document.getElementsByClassName("included")];
		},
		onClickOutside() {
			this.isInputExpandedNew = false;
			this.isOpen = false;
			this.searchData = "";
			this.$refs.refInput.blur()
		},
        onClickMenuAccount() {
            this.isInputExpandedNew = false;
            this.searchData = "";
            this.isOpen = false;
        }
    },
    computed: {
        ...mapGetters(["getSpecialDialog", "getLoadingUserDetails"]),
        defaultCustomerEntity() {
            if (this.customers.length > 0) {
                return _.find(this.customers, {
                    is_selected: 1,
                });
            } else {
                let getUser = this.$store.getters.getUser;
                let getCustomers =
                typeof getUser.customers_api !== "undefined"
                    ? getUser.customers_api
                    : JSON.parse(getUser);
                return {
                    name: getCustomers.name,
                    address: getCustomers.email,
                };
            }
        },
        defaultCustomer() {
            let getUser = this.$store.getters.getUser;
            getUser = typeof getUser === "string" && JSON.parse(getUser);
            return getUser.default_customer_id;
        },
        email() {
            let getUser = this.$store.getters.getUser;
            return typeof getUser.name !== "undefined"
                ? getUser.email
                : JSON.parse(getUser).email;
        },
        name() {
            let getUser = this.$store.getters.getUser;
            return typeof getUser.name !== "undefined"
                ? getUser.name
                : JSON.parse(getUser).name;
        },
        customers() {
            let getUser = this.$store.getters.getUser;
            let getCustomers =
                typeof getUser.customers_api !== "undefined"
                    ? getUser.customers_api
                    : JSON.parse(getUser).customers_api;
            if (typeof getCustomers !== "undefined" && getCustomers.length > 0) {
                getCustomers.map((customer, key) => {
                    (getCustomers[key].is_selected =
                        this.defaultCustomer !== null && this.defaultCustomer == customer.id
                            ? 1
                            : 0),
                        (getCustomers[key].name = customer.company_name),
                        (getCustomers[key].company_name =
                            customer.address !== null && customer.address !== ""
                                ? customer.address
                                : "No address found.");
                });
                let findSelected = _.find(getCustomers, { is_selected: 1 });
                if (typeof findSelected == "undefined") getCustomers[0].is_selected = 1;
                getCustomers = _.orderBy(getCustomers, ["is_selected"], ["desc"]);
                return getCustomers;
            }
            return [];
        },
        companyKey(){
            if(!this.getLoadingUserDetails){
                if(this.defaultCustomerEntity.company_key == "" || this.defaultCustomerEntity.company_key == null){
                    return 'No key found';
                }else{
                    return 'Key: '+ this.defaultCustomerEntity.company_key 
                }
            }
            return '';
        },
        filteredCustomers() {
            let filteredItems = [];
            
            if (this.searchData !== "") {
                filteredItems = _.filter(this.customers, e => {
                    // (e.name.toLowerCase().indexOf(this.searchData.toLowerCase()) !== -1)
                    return typeof e.name !== "undefined" && e.name !== null 
                        ? (e.name.toLowerCase().indexOf(this.searchData.toLowerCase()) !== -1) : [];
                })
            } else filteredItems = this.customers;

            return filteredItems;
        },
    },
    mounted() {},
    created() {
        let customerId = this.$route.query.customerId;
        if(customerId) {
            this.selectCustomer({id: customerId});
        }
    }
};
</script>
