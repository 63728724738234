<template>
	<div>
		<v-dialog v-model="openDialogNotification" 
			max-width="560" 
			content-class="notifications-dialog-wrapper" 
			scrollable
			transition="slide-x-reverse-transition"
			@click:outside="closeDialog"
			ref="notificationModal">

			<template v-slot:activator="{ on, attrs }">
				<v-badge
					overlap
					:content="unreadNotificationsCount"
					color="red"
					:value="hasNewNotifications"
					class="notification-badge-no"
					:class="{
						'ml-3': $vuetify.breakpoint.width < 1024,
						'mr-3': !$vuetify.breakpoint.width < 1024,
					}">
					
					<v-btn
						@click="openDialog"
						small
						icon
						:color="$vuetify.breakpoint.width < 1024 ? 'white' : 'shifl'"
						v-bind="attrs"
						v-on="on">
						<img src="@/assets/icons/bell.png" alt="" height="20px" width="20px" v-if="!isMobile">
						<img src="@/assets/icons/bell-white.png" alt="" height="20px" width="20px" v-else>
					</v-btn>
				</v-badge>
			</template>

			<v-card class="notification-card-wrapper" ref="notificationModalCard" 
				:loading="(getNotifGlobalLoading && !isFetchingOtherData) || initialLoading" 
				:disabled="(getNotifGlobalLoading && !isFetchingOtherData) || initialLoading">
				<!-- :loading="notificationsIsLoading || getNotificationsIsLoadingShipment"
				:disabled="notificationsIsLoading || getNotificationsIsLoadingShipment" -->

				<v-card-title class="notification-header" style="font-size: 18px; border-width: 1px;"
					:style="isShowArchived ? 'padding: 16px 24px 16px 16px !important;' : ''">
					<div class="d-flex">
						<button v-if="isShowArchived" class="mr-2" @click="goBack()">
							<v-icon color="#1A6D9E">mdi-chevron-left</v-icon>
						</button>

						<span class="headline">{{ !isShowArchived ? 'Notifications' : 'Archived Notifications'}}</span>
					</div>

					<button icon dark class="btn-close" @click="closeDialog">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				
				<v-card-text class="px-0 pb-0 notification-card-body" :class="!isShowArchived ? 'default-filter' : ''">
					<div class="notification-top-header" v-if="!isShowArchived && (isSorting || notificationLists.length)">
						<div class="d-flex justify-space-between align-center px-5 py-3">
							<div class="d-flex align-center filter-notification-items">
								<v-select
									class="sortItems change-color mt-0"
									v-model="sortBy"
									dense
									:menu-props="{
										contentClass: 'notification-type-menu',
										bottom: true,
										offsetY: true,
									}"
									:items="items"
									append-icon="mdi-chevron-down"
									hide-details="auto"
									prefix="Show"
									@change="onChangeSortBy"
									attach>
								</v-select>
							</div>
							<v-tooltip left content-class="notification-tooltip-settings tooltip-left">
								<template v-slot:activator="{ on }">
									<button class="d-flex align-center" @click="navigateToNotificationSettings">
										<img src="@/assets/images/settings.png" width="20px" height="20px" v-on="on">
									</button>
								</template>

								<span>Notification Settings</span>
							</v-tooltip>
						</div>
					</div>

					<v-list v-if="notificationLists.length && !isShowArchived" class="overflow-auto notification-lists-import pt-0 pb-0">
						<div class="notification-lists-unarchived">
							<!-- show notifications that are not archived -->
							<Notification
								v-for="(notification) in notificationLists"
								:key="notification.id"
								:notification="notification"
								:isShowArchived="isShowArchived"
								:hasNewNotifications="hasNewNotifications"
								:sortBy="sortBy"
								@close="closeDialog" />
						</div>
					</v-list>

					<v-list v-else-if="archivedLists.length && isShowArchived" class="overflow-auto notification-lists-import pt-0 pb-0">
						<div class="notification-lists-archived">
							<!-- show notifications that are archived -->
							<Notification
								v-for="(notification) in archivedLists"
								:key="notification.id"
								:notification="notification"
								:isShowArchived="isShowArchived"
								:hasNewNotifications="hasNewNotifications"
								:sortBy="sortBy"
								@close="closeDialog" />
						</div>
					</v-list>

					<div v-else-if="(notificationLists.length === 0 || archivedLists.length === 0) && (!getNotificationsIsLoadingShipment && !getSortedMessagesLoading)" 
						class="py-4 text-center empty-notification-list" style="color: #253041;">

						<div v-if="!isSorting">
							<img src="@/assets/icons/large-notification.png" alt="" height="64px" width="64px">
							<h3>You have no {{ isShowArchived ? 'archived' : '' }} notification</h3>
							<p v-if="!isShowArchived">
								It looks like you don't have any new notifications at the moment, 
								but don't worry - we'll keep you informed as soon as there's something new to share
							</p>
							<p v-else>
								It looks like you don't have any archived notifications at the moment
							</p>
						</div>
						<div v-else>
							It looks like you don't have any notifications at the moment
						</div>
					</div>

					<div class="d-flex align-center justify-center" style="height: calc(100% - 180px);" 
						v-if="getSortedMessagesLoading && isSorting && !isFetchingOtherData">
						<v-progress-circular
							:size="30"
							color="#0171a1"
							indeterminate>
						</v-progress-circular>
					</div>

					<div class="notification-skeleton-loading" v-if="isFetchingOtherData"> 
						<v-skeleton-loader v-for="n in 5" :key="n"
							class="px-4"
							max-width="100%"
							height="62"
							max-height="62"
							type="list-item-two-line">
						</v-skeleton-loader>
					</div>
				</v-card-text>

				<v-card-actions class="pa-0">
					<div class="v-card-actions-buttons" v-if="!isShowArchived">
						<button @click="goToArchive"							
							style="letter-spacing: 0 !important;"
							color="#6D858F">
							<span style="color: #6D858F; font-size: 14px;">
								View All Archived
							</span>
						</button>
						
						<button v-if="notificationLists.length"
							:disabled="!hasUnreadNotifications || (notificationsIsLoading || getNotificationsIsLoadingShipment)"
							@click.stop="markAllNotifAsRead"
							style="letter-spacing: 0 !important;"
							color="#253041">
							<span class="font-medium" style="color: #1A6D9E; font-size: 14px;">
								Mark All as Read
							</span>
						</button>
					</div>

					<div class="v-card-actions-buttons" v-else-if="archivedLists.length && isShowArchived">
						<button style="letter-spacing: 0 !important;"
							color="#6D858F"
							@click="unarchiveAllNotif">
							<span style="color: #6D858F; font-size: 14px;">
								Unarchive All
							</span>
						</button>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import BaseNotification from "@/components/Notifications/BaseNotification";
import Notification from "@/components/Notifications/Notification";

export default {
	name: "Notifications",
	props: ["isMobile"],
	components: {
		Notification,
		// BaseNotification
	},
	data() {
		return {
			openDialogNotification: false,
			// items: ["All", "Booking", "Delivery Order", "Shipment"],
			items: [
				"All",
				"Booking Created", 
				"Booking Confirmed", 
				"Booking Updated", 
				"Departure Notice", 
				"Arrival Notice", 
				"Delivery Order", 
				"ETA Changed Alert", 
			],
			sortBy: "All",
			isShowArchived: false,
			isSorting: false,
			bottom: false,
			current_page: 1,
			initialLoading: false,
			isFetchingOtherData: false,
			isClickedSorting: false,
			isScrolling: false
		};
	},
	async mounted() {
		this.initialLoading = true;
		// this.fetchNotifications(); - to show PO notifications
		// await this.getNotificationFromApi(1);
		// await this.getArchivedMessages(1);
		this.initialLoading = false;
	},
	computed: {
		...mapGetters({
			getPONotif: "notifications/getNotifications",
			hasNew: "notifications/getHasNew",
			hasNewShipmentNotif: "notificationNew/getHasNew",
			notificationsIsLoading: "notifications/getNotificationsIsLoading",
			getUser: "getUser",
			getMessages: "notificationNew/getMessages",
			getNotificationsIsLoadingShipment: "notificationNew/getNotificationsIsLoadingShipment",
			getArchMessages: "notificationNew/getArchMessages",
			getNotifGlobalLoading: "notificationNew/getNotifGlobalLoading",
			getSortedMessages: "notificationNew/getSortedMessages",
			getSortedMessagesLoading: "notificationNew/getSortedMessagesLoading",
			getShipmentDocumentPreview:'notificationNew/getShipmentDocumentPreview'
		}),
		hasUnreadNotifications() {
			return this.notificationLists.some((n) => n.read_at === null);
		},
		unreadNotificationsCount: {
			get() {
				let countUnread = 0;
				if (this.allNotificationLists.length > 0) { // test
					let a = this.allNotificationLists.filter((v) => v.read_at === null);
					countUnread = a.length
				}
				return countUnread;
			},
			set(v) {
				return v
			}
		},
		hasNewNotifications() {
			return this.hasNew || this.hasNewShipmentNotif ? true : false
		},
		// combining all notifications
		allNotificationLists() {
			let data = [];
			if (!this.isShowArchived) {
				let poNotifs = this.getPONotif, shipmentNotif = this.getMessages.notifications;
				data = [ ...poNotifs, ...shipmentNotif ];
				data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
			}
			return data;
		},
		notificationLists() {
			let data = [];
			if (!this.isSorting) {
				data = this.allNotificationLists.filter(val => val.archived_at === null);
			} else {
				// if (!this.getNotifGlobalLoading) {
					data = this.sortingNotificationLists.filter(val => val.archived_at === null);
				// }
			}
			return data;
		},
		archivedLists() {
			let data = [];
			data = [ ...this.getArchMessages.notifications ];
			data.sort((a, b) => new Date(b.archived_at) - new Date(a.archived_at))
			return data;			
		},
		sortingNotificationLists() {
			let data = [];
			data = [ ...this.getSortedMessages.notifications ];
			data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
			return data;	
		},		
		currentCustomer() {
			let getUser;
			if (typeof this.getUser === "string") {
				getUser = JSON.parse(this.getUser);
			} else {
				getUser = this.getUser;
			}
			return getUser;
		},
	},
	methods: {
		...mapMutations({
			setHasNew: "notifications/SET_HAS_NEW",
			setHasNewShipmentNotif: "notificationNew/SET_HAS_NEW",
		}),
		...mapActions({
			fetchNotifications: "notifications/fetchNotifications",
			markAllAsRead: "notifications/markAllAsRead",
			getNotificationFromApi: "notificationNew/getNotificationFromApi",
			markAllShipmentAsRead: "notificationNew/markAllShipmentAsRead",
			getArchivedMessages: "notificationNew/getArchivedMessages",
			unarchiveAllShipmentNotification: "notificationNew/unarchiveAllShipmentNotification",
			getSortedNotificationFromApi: "notificationNew/getSortedNotificationFromApi",
			setFilteredNotificationsVal: "notificationNew/setFilteredNotificationsVal"
		}),
		extractComponentName(string) {
			const componentName = string.split("\\").pop();
			return this.checkIfComponentIsAvailable(componentName)
				? componentName
				: "BaseNotification";
		},
		checkIfComponentIsAvailable(componentName) {
			return !!this.$options.components[componentName];
		},
		openDialog() {
			this.setHasNew(false);
			this.unreadNotificationsCount = 0;
			this.openDialogNotification = true;
			this.setHasNewShipmentNotif(false);
		},
		closeDialog() {
			this.openDialogNotification = false;
			this.isShowArchived = false;
			this.isSorting = false;
			this.sortBy = "All";
			this.isClickedSorting = false;
			this.isScrolling = false;
			this.setFilteredNotificationsVal();
		},
		navigateToNotificationSettings() {
			this.closeDialog();
			this.$router.push("settings?tab=notifications").catch(() => {});
		},
		goToArchive() {
			// this.scrollToTop();
			this.isShowArchived = true;
			this.isSorting = false;
			this.sortBy = "All";
			this.isClickedSorting = false;
			this.isScrolling = false;
		},
		goBack() {
			// this.scrollToTop();
			this.isShowArchived = false;
		},
		markAllNotifAsRead() {
			// this.markAllAsRead(); - PO notifications mark as read 
			let sortedValue = this.calculateValueSort(this.sortBy)
			this.markAllShipmentAsRead(sortedValue);
		},
		unarchiveAllNotif() {
			this.unarchiveAllShipmentNotification();
		},
		async sortNotification(sort, page) {
			let payload = { page, types: [sort], isSorting: this.isClickedSorting, isScrolling: this.isScrolling }

			if (sort !== "all") {
				payload.types = [sort];
				await this.getSortedNotificationFromApi(payload);
				this.isSorting = true;
			} else {
				// let currentPage = this.getMessages.page;
				// await this.getNotificationFromApi(currentPage);
				this.isClickedSorting = false;
				this.isSorting = false;
				this.isScrolling = false;
				// await this.getSortedNotificationFromApi(payload);
			}
		},
		scrollToTop() {
			setTimeout(() => {
				var modal = this.$refs.notificationModalCard;
				var wrapper = modal.$el.querySelector('.notification-card-body');
				this.$vuetify.goTo(modal); // to modal
				this.$vuetify.goTo(modal, {container: wrapper}); // to header
			}, 10);
		},
		bottomVisible() {
            const div = document.getElementsByClassName('notification-card-body')
			let target = div[0];
			let targetBottomHeight = target.scrollHeight - target.offsetHeight;

			if (Math.ceil(target.scrollTop) >= targetBottomHeight - 50) {
				this.bottom = true;
				let currentPage = this.isShowArchived 
					? this.getArchMessages.page 
					: !this.isSorting
					? this.getMessages.page 
					: this.getSortedMessages.page

				this.current_page = currentPage+=1;
			} else {
				this.bottom = false;
			}
			this.isScrolling = true;
        },
		onChangeSortBy(val, page, scrolling) {
			this.isClickedSorting = true;
			this.isScrolling = typeof scrolling === "undefined" ? false : scrolling;
			page = typeof page === "undefined" ? 1 : page;

			let sortedValue = this.calculateValueSort(val);
			this.sortNotification(sortedValue, page);
		},
		calculateValueSort(val) {
			if (val === "All") return "all";
			else if (val === "Booking Created") return "booking_created";
			else if (val === "Booking Confirmed") return "booking_confirmed";
			else if (val === "Booking Updated") return "booking_updated";
			else if (val === "Departure Notice") return "departure_notice_sent";
			else if (val === "Arrival Notice") return "arrival_notice_sent";
			else if (val === "Delivery Order") return "delivery_order_sent";
			else return "eta_changed";
		}
	},
	watch: {
		openDialogNotification(val) {
			if (val) {
				setTimeout(() => {
					let target = document.querySelector(".notification-card-body");
					target.addEventListener('scroll', this.bottomVisible);
				}, 100);
				// this.scrollToTop();
			}
		},
		async bottom(v) {
			if (v) {
				let currentLastPage = this.isShowArchived 
					? this.getArchMessages.last_page 
					: !this.isSorting
					? this.getMessages.last_page
					: this.getSortedMessages.last_page;
				
				if (this.current_page <= currentLastPage && !this.getNotificationsIsLoadingShipment) {
					this.isFetchingOtherData = true;
					let payload = { id: this.currentCustomer.default_customer_id, page: this.current_page }
					if (this.isShowArchived) {
						await this.getArchivedMessages(payload);
					} else {
						if (!this.isSorting) await this.getNotificationFromApi(payload);
						else await this.onChangeSortBy(this.sortBy, this.current_page, true);
					}
					setTimeout(() => {
						this.isFetchingOtherData = false;
					}, 1000);
				}
			}
        },
		async notificationLists(val) {
			let sortBy = this.calculateValueSort(this.sortBy);
			if (val.length < 15) {
				if (sortBy === "all") {
					let lastPage = this.getMessages.last_page;
					let page = this.getMessages.page;
					if (lastPage > page) {
						page = page + 1;
						await this.getNotificationFromApi(page);
					}
				} else {
					let lastPage = this.getSortedMessages.last_page;
					let page = this.getSortedMessages.page;
					if (lastPage > page) {
						page = page + 1;
						let payload = { page, types: [sortBy], isSorting: this.isClickedSorting, isScrolling: this.isScrolling }
						await this.getSortedNotificationFromApi(payload);
					}
				}
			}
		},
		sortBy(nv, ov) {
			if (nv !== ov && this.isClickedSorting) {
				this.setFilteredNotificationsVal();
			}
		}
	},
};
</script>
<style lang="scss">
@import "@/assets/scss/pages_scss/dialog/globalDialog.scss";

.v-menu__content {
	&.notification-type-menu {
		width: 216px !important;
		min-width: 216px !important;
	}
}

.notification-badge-no {
	.v-badge__wrapper {
		.v-badge__badge {
			inset: auto auto calc(100% - 12px) calc(100% - 16px) !important;
			background-color: #F93131 !important;
			border-color: #F93131 !important;
			font-size: 11px;
			min-width: 18px;
			height: 18px;
		}
	}

	button {	
		height: 32px;
    	width: 32px;

		&:hover {
			background-color: #F0F9FF !important;
		}

		&::before {
			display: none;
		}
	}
}

.v-dialog {
	&.notifications-dialog-wrapper {
		max-height: 100% !important;
		height: 100% !important;
		right: 0 !important;
		margin: 0%;
		position: absolute !important;
		border-radius: 0 !important;
		overflow: hidden;

		.v-card {
			border-radius: 0;
			.v-skeleton-loader {
				border-bottom: 1px solid $shifl-neutral-n20;
				.v-skeleton-loader__list-item-two-line {
					height: 62px;
				}
			}

			.v-card__actions {
				border: none !important;
				height: 60px;

				.v-card-actions-buttons {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					height: 100%;
					padding: 8px 24px;
					border-top: 1px solid $shifl-neutral-n20;
				}
			}

			.v-card__text {
				&.default-filter {
					&::-webkit-scrollbar-track {
						margin-top: 50px !important;
					}
				}				
			}
		}

		.notification-top-header {
			position: sticky;
			z-index: 9999;
			top: 0;
			background-color: #fff;

			.filter-notification-items {
				&:hover {
					border-radius: 4px;
					background-color: #F7F7F7 !important;

					.v-input {
						.v-input__control {
							background-color: #F7F7F7 !important;
							border-radius: 4px;
						}
					}
				}

				.v-input {
					&.v-select--is-menu-active {
						.v-input__control {
							background-color: #F7F7F7 !important;
							border-radius: 4px;
						}
					}

					&.sortItems {
						.v-input__control {
							.v-input__slot {
								.v-text-field__prefix {
									color: #69758C; 
									font-size: 14px;
									padding: 2px 6px 2px;
								}

								.v-select__selections {
									.v-select__selection {
										color: #253041 !important;
										font-size: 14px !important;
										font-weight: 500;
										font-family: "Inter-Medium", sans-serif !important;
										margin-top: 2px;
										max-width: 300px;
									}

									input {
										width: 10px;
									}
								}

								.v-input__append-inner {
									.v-input__icon {
										i {
											font-size: 22px;
											color: #1A6D9E !important;
											margin-top: 2px;
										}
									}
								}

								&::before,
								&::after {
									display: none;
								} 
							}
						}
					}
				}
			}
		}

		::-webkit-scrollbar {
			width: 6px !important;
		}

		::-webkit-scrollbar-track {
			background-color: #F1F4F6; 
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background-color: #D6D9DC;
			border-radius: 4px;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background-color: #D6D9DC;
		}
	}

	.notification-lists-import {
		&::-webkit-scrollbar {
			width: 6px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background-color: #F1F4F6; 
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background-color: #D6D9DC;
			width: 6px;
			border-radius: 4px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background-color: #D6D9DC;
		}
		
		.v-list-item {
			padding: 0 18px !important;
			min-height: 50px;

			&:hover {
				background-color: $shifl-primary-b10 !important;

				.v-list-item__subtitle {
					.archive-notification {
						display: flex !important;
					}
				}
			}

			&::before {
				display: none;
			}

			.v-list-item__subtitle {
				.archive-notification {
					display: none !important;
				}
			}
		}
	}

	.empty-notification-list {
		// height: 100%;
		height: calc(100% - 50px);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 24px;

		h3 {
			font-weight: unset;
			font-size: 20px;
			color: $shifl-neutral-n90;
			font-family: "Inter-SemiBold", sans-serif;
			margin: 8px 0 12px;
		}

		p {
			width: 400px;
			line-height: 20px;
			color: $shifl-neutral-n90;
		}
	}
}

.v-tooltip__content.notification-tooltip-settings {
	background-color: $shifl-neutral-n90 !important;
    padding: 6px 12px !important;
    max-width: unset !important;
    font-size: 14px !important;
    line-height: unset !important;

	&.menuable__content__active {
		opacity: 1 !important;
		background-color: $shifl-neutral-n90 !important;
	}

	&.tooltip-left::before{
		content: "";
		position: absolute;
		top: 50%;
		left: 100%; /* To the left of the tooltip */
		margin-top: -8px;
		border-width: 8px;
		border-style: solid;
		border-top: solid 8px transparent;
		border-bottom: solid 8px transparent;
		border-right: solid 8px transparent;
		border-left: solid 8px $shifl-neutral-n90 !important;
	}

	&.tooltip-top::before {
		border-right: solid 8px transparent;
		border-left: solid 8px transparent;
		transform: translateX(-50%);
		position: absolute;
		z-index: -21;
		content: '';
		top: 100%;
		left: 50%;
		height: 0;
		width: 0;
		border-top: solid 8px $shifl-neutral-n90;
	}

	&.tooltip-bottom::before {
		border-right: solid 8px transparent;
		border-left: solid 8px transparent;
		transform: translateX(-50%);
		position: absolute;
		z-index: -21;
		content: '';
		bottom: 100%;
		left: 50%;
		height: 0;
		width: 0;
		border-bottom: solid 8px $shifl-neutral-n90;
	}
}


@media screen and (max-width: 768px) {
	.v-dialog {
		.empty-notification-list {
			img {
				width: 40px !important;
				height: 40px !important;
			}

			p {
				width: 100% !important;
				line-height: 20px;
				color: $shifl-neutral-n90;
				padding: 0 22px !important;
			}
		}

		.notification-lists-import {
			.v-list-item {
				.v-list-item__title {
					margin-bottom: 6px !important;

					span {
						font-family: "Inter-Medium", sans-serif !important;
					}
				}
			}
		}

		.notification-top-header {
			.filter-notification-items {
				.v-input {
					&.sortItems {
						.v-input__control {
							.v-input__slot {
								.v-select__selections {
									.v-select__selection {
										width: unset !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
