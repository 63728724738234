<template>
	<v-list-item-content>
		<v-list-item-title class="mb-1">
			<!-- <span style="color: #253041;" class="font-medium">{{ title }}</span> -->
			<span v-if="!hasBeenRead" class="mr-1">
				<v-icon color="#0171A1" size="12px">mdi-circle</v-icon>
			</span>
			
			<span style="color: #253041;" class="font-medium" 
				:style="hasBeenRead ? 'margin-left: 20px;' : ''">
				{{ title }}
			</span>
		</v-list-item-title>

		<v-list-item-subtitle style="color: #69758C; margin-left: 19px;" class="font-regular">
			<div class="d-flex justify-space-between align-center">
				<span class="font-regular"> {{ subTitle }}</span>

				<!-- <v-tooltip left content-class="notification-tooltip-settings tooltip-left">
					<template v-slot:activator="{ on }">
						<span class="archive-notification d-flex align-center">
							<img src="@/assets/icons/archive.png" alt="archive" width="16px" height="16px" v-on="on">
						</span>				
					</template>

					<span>{{ !isShowArchived ? 'Archive' : 'Unarchive' }}</span>
				</v-tooltip> -->
			</div>
		</v-list-item-subtitle>

		<ImportEntityDialog
			ref="importProductEntityDialog"
			:importFn="importPOs"
			:import-errors="importErrors"
			templateText="You can import PO by uploading Excel or CSV file. To ensure accurate import, download and use this template:"
			:template-url="templateURL"
			title="Upload PO"
			:isOpen.sync="isOpen"
		/>
	</v-list-item-content>
</template>
<script>
import ImportEntityDialog from "@/components/Dialog/ImportEntityDialog";
import { mapActions } from "vuex";
import BaseNotification from "@/components/Notifications/Types/BaseNotification";

export default {
	name: "ImportPurchaseOrdersFailedNotification",
	extends: BaseNotification,
	components: { ImportEntityDialog },
	data() {
		return {
			importErrors: {},
			isOpen: false,
			templateURL: `${process.env.VUE_APP_PO_URL}/orders/export-template`,
		};
	},
	created() {
		this.importErrors.file = this.notification.data?.file;
		this.importErrors.errors = this.notification.data.errors;
	},
	methods: {
		...mapActions({
			importPOs: "po/importPOs",
		}),
		onOpen() {
			this.isOpen = true;
		},
	},
};
</script>

<style scoped></style>
