import { render, staticRenderFns } from "./Notification.vue?vue&type=template&id=76b34cee&scoped=true&"
import script from "./Notification.vue?vue&type=script&lang=js&"
export * from "./Notification.vue?vue&type=script&lang=js&"
import style0 from "./Notification.vue?vue&type=style&index=0&id=76b34cee&scoped=true&lang=css&"
import style1 from "./Notification.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b34cee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VDivider,VListItem})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
