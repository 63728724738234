import axios from "axios"
import store from '../'
const state = {
    createGroupType:'UsePredefinedGroup',
    UserAndUserGroupActveTab:0,
    addGroup:[],
    addGroupLoading:false,
    addDeliveryLocation:[],
    addDeliveryLocationLoading:false,
    allDeliveryLocationLoading:false,
    allDeliveryLocation:[],
    singleDeliveryLocationLoading: false,
    singleDeliveryLocation:[],
    updateDeliveryLocationLoading:false,
    updateDeliveryLocation:[],
    editGroupLoading:false,
    editGroup:[],
    deleteGroupLoading:false,
    groupDeleteWithoutMoveUserToOtherGroupLoading:false,
    groupUsers:[],
    groupUsersLoading:false ,
    groupWithUsersAndPermissions:[],
    groupDeliveryLocations:[],
    groupWithUsersAndPermissionsLoading:false,
    removeUsersFromGroupLoading:false,
    addUsersInGroupLoading:false,
    addUsersInGroup:[],
    groupDeleteAndMoveUserToOtherGroupLoading:false,
    addGroupPermissions:[],
    addGroupPermissionsLoading:false,
    updateGroupPermissionsLoading:false,
    groupPermissions:[],
    groupPermissionsLoading:false,
    allDefaultGroupTemplates:[],
    allDefaultGroupTemplatesLoading:false,
    groupTemplatePermissions:[],
    groupTemplatePermissionsLoading:false,
    allModules:[],
    allModulesLoading:false,
    groupDetails:[],
    groupDetailsLoading:false,
    // import Name
    allImportNameLoading:false,
    allImportName:[],
    importNameDetailsLoading:false,
    importNameDetails:[],
    updateImportNameLoading:false,
    createImportNameLoading:false,
    deleteImportNameLoading:false,
    deleteDeliveryLocation:false,
    deleteDeliveryLocationLoading:false,
    deleteContactLoading:false,
    deleteContact:false,
    deactivateAccountLoading: false,
    deactivateAccount: false,
    reactivateAccountLoading: false,
    reactivateAccount: false,
    allImportNameDropdown: [],
    deliveryLocationLoading: false,
    activeTabOfCompanyProfile:0
}
const getters = {
    getAddGroup : state => state.addGroup,
    getAddGroupLoading: state => state.addGroupLoading,
    getAddDeliveryLocation : state => state.addDeliveryLocation,
    getAddDeliveryLocationLoading: state => state.addDeliveryLocationLoading,
    getAllDeliveryLocation: state => state.allDeliveryLocation,
    getAllDeliveryLocationLoading: state => state.allDeliveryLocationLoading,
    getSingleDeliveryLocation: state => state.singleDeliveryLocation,
    getSingleDeliveryLocationLoading: state => state.singleDeliveryLocationLoading,
    getupdateDeliveryLocationLoading: state => state.updateDeliveryLocationLoading,
    getupdateDeliveryLocation: state => state.updateDeliveryLocation,
    getDeleteDeliveryLocationLoading: state => state.deleteDeliveryLocationLoading,
    getDeleteDeliveryLocation: state => state.deleteDeliveryLocation,
    getDeleteContactLoading: state => state.deleteContactLoading,
    getDeleteContact: state => state.deleteContact,
    getDeActivateAccountLoading: state => state.deactivateAccountLoading,
    getDeActivateAccount: state => state.deactivateAccount,
    getReActivateAccountLoading: state => state.reactivateAccountLoading,
    getReActivateAccount: state => state.reactivateAccount,
    getEditGroupLoading: state => state.editGroupLoading,
    getEditGroup: state => state.editGroup,
    getDeleteGroupLoading: state => state.deleteGroupLoading,
    getGroupUsersLoading: state => state.groupUsersLoading,
    getGroupUsers: state => state.groupUsers,
    getGroupWithUsersAndPermissions: state => state.groupWithUsersAndPermissions,
    getGroupWithUsersAndPermissionsLoading: state => state.groupWithUsersAndPermissionsLoading,
    getDeliveryLocations: state => state.groupDeliveryLocations,
    getRemoveUsersFromGroupLoading: state => state.removeUsersFromGroupLoading,
    getAddUsersInGroupLoading: state => state.addUsersInGroupLoading,
    getAddUsersInGroup: state => state.addUsersInGroup,
    getGroupDeleteAndMoveUserToOtherGroupLoading: state => state.groupDeleteAndMoveUserToOtherGroupLoading,
    getGroupDeleteWithoutMoveUserToOtherGroupLoading: state => state.groupDeleteWithoutMoveUserToOtherGroupLoading,
    getAddGroupPermissionsLoading: state => state.addGroupPermissionsLoading,
    getAddGroupPermissions: state => state.addGroupPermissions,
    getUpdateGroupPermissionsLoading: state => state.updateGroupPermissionsLoading,
    getGroupPermissions: state => state.groupPermissions,
    getGroupPermissionsLoading: state => state.groupPermissionsLoading,
    getAllDefaultGroupTemplatesLoading: state => state.allDefaultGroupTemplatesLoading,
    getAllDefaultGroupTemplates: state => state.allDefaultGroupTemplates,
    getGroupTemplatePermissions: state => state.groupTemplatePermissions,
    getGroupTemplatePermissionsLoading: state => state.groupTemplatePermissionsLoading,
    getAllModules: state => state.allModules,
    getAllModulesLoading: state => state.allModulesLoading,
    getGroupDetails: state => state.groupDetails,
    getGroupDetailsLoading: state => state.groupDetailsLoading,
    getUserAndUserGroupActveTab: state => state.UserAndUserGroupActveTab,
    // Import Name
    getAllImportNameLoading: state => state.allImportNameLoading,
    getAllImportName: state => state.allImportName,
    getImportNameDetailsLoading: state => state.importNameDetailsLoading,
    getImportNameDetails: state => state.importNameDetails,
    getUpdateImportNameLoading: state => state.updateImportNameLoading,
    getCreateImportNameLoading: state => state.createImportNameLoading,
    getDeleteImportNameLoading: state => state.deleteImportNameLoading,
    getAllImportNameDropdown: state => state.allImportNameDropdown,
    getDeliveryLocationLoading: state => state.deliveryLocationLoading,
    getActiveTabOfCompanyProfile:state => state.activeTabOfCompanyProfile
}
const poBaseUrl = process.env.VUE_APP_BASE_URL
const actions = {
    addGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_ADD_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups`, item).then(response => {
                    // console.log("addGroup",response)
                    commit('SET_ADD_GROUP_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_GROUP', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ADD_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_ADD_GROUP_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    editGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_EDIT_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/update`, item).then(response => {
                    commit('SET_EDIT_GROUP_LOADING', false)
                    commit('SET_EDIT_GROUP',response.data.data)
                    resolve(response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_EDIT_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_EDIT_GROUP_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    deleteGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_DELETE_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/delete`, item).then(response => {
                    commit('SET_DELETE_GROUP_LOADING', false)
                    resolve(response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DELETE_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_DELETE_GROUP_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchGroupUsersApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_GROUP_USERS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/groups/${item.group_id}/users?company_id=${item.company_id}`).then(response => {
                    // console.log("users",response)
                    commit('SET_GROUP_USERS_LOADING', false)
                    resolve(response.data)
                    commit('SET_GROUP_USERS', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_USERS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_USERS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    addDeliveryLocationApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_ADD_DELIVERY_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`delivery-location`, item).then(response => {
                    // console.log("addGroup",response)
                    commit('SET_ADD_DELIVERY_GROUP_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_DELIVERY_GROUP', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ADD_DELIVERY_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_ADD_DELIVERY_GROUP_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    fetchDeliveryLocationsApi: async ({ commit }, payload) => {
        let attempt = false
        commit("SET_DELIVERY_LOCATIONS_LOADING", true);
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`delivery-location?location_id=${payload}`).then(response => {
                   
                    resolve(response.data)
                    commit('SET_DELIVERY_LOCATIONS', response.data.results.data)
                    commit("SET_DELIVERY_LOCATIONS_LOADING", false);
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DELIVERY_LOCATIONS_LOADING", false);
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                           commit("SET_DELIVERY_LOCATIONS_LOADING", false);
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchSingleDeliveryLocation: async ({ commit }, id) => {
        let attempt = false
        commit('SET_SINGLE_DELIVERY_LOCATION_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`delivery-location/${id}`).then(response => {
                    commit('SET_SINGLE_DELIVERY_LOCATION_LOADING', false)
                    resolve(response.data)
                    commit('SET_SINGLE_DELIVERY_LOCATION', response.data)
                    
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_SINGLE_DELIVERY_LOCATION_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_SINGLE_DELIVERY_LOCATION_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },

    updateDeliveryLocationApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_UPDATE_DELIVERY_LOCATION_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.put(`delivery-location/${item.id}`,item).then(response => {
                    commit('SET_UPDATE_DELIVERY_LOCATION_LOADING', false)
                    resolve(response.data)
                    
                    commit('SET_UPDATE_DELIVERY_LOCATION', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_UPDATE_DELIVERY_LOCATION_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_UPDATE_DELIVERY_LOCATION_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },

    deleteLocationApi: async ({ commit }, id) => {
        let attempt = false
        commit('SET_DELETE_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.delete(`delivery-location/${id}`).then(response => {
                    commit('SET_DELETE_IMPORT_NAME_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_GROUP_PERMISSIONS', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DELETE_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_DELETE_IMPORT_NAME_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    deleteContactApi: async ({ commit }, id) => {
        let attempt = false
        commit('SET_DELETE_CONTACT_LOADING', true)
        
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.delete(`delivery-location/contact/${id}`).then(response => {
                    commit('SET_DELETE_CONTACT_LOADING', false)
                    resolve(response.data)
                    commit('SET_DELETE_CONTACT', true)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DELETE_CONTACT_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_DELETE_CONTACT_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    
    deactivateAccountApi: async ({ commit }, payload) => {
        let attempt = false
        commit('SET_DEACTIVATE_ACCOUNT_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`deactivate-user?customer_admin_id=${payload.id}&type=${payload.type}`).then(response => {
                    commit('SET_DEACTIVATE_ACCOUNT_LOADING', false)
                    resolve(response.data)
                    commit('SET_DEACTIVATE_ACCOUNT', true)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DEACTIVATE_ACCOUNT_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_DEACTIVATE_ACCOUNT_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    reactivateAccountApi: async ({ commit }, customer_adming_id) => {
        let attempt = false
        commit('SET_REACTIVATE_ACCOUNT_LOADING', true)

        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`activate-user?customer_admin_id=${customer_adming_id}`).then(response => {
                    commit('SET_REACTIVATE_ACCOUNT_LOADING', false)
                    resolve(response.data)
                    commit('SET_REACTIVATE_ACCOUNT', true)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_REACTIVATE_ACCOUNT_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_REACTIVATE_ACCOUNT_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    fetchGroupWithUsersAndPermissions: async ({ commit }, company_id) => {
        let attempt = false
        commit('SET_GROUP_WITH_USERS_AND_PERMISSIONS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/groups/${company_id}/company`).then(response => {
                    // console.log("response", response)
                    commit('SET_GROUP_WITH_USERS_AND_PERMISSIONS_LOADING', false)
                    resolve(response.data)
                    commit('SET_GROUP_WITH_USERS_AND_PERMISSIONS', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_WITH_USERS_AND_PERMISSIONS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_WITH_USERS_AND_PERMISSIONS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    removeUsersFromGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_REMOVE_USERS_FROM_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/remove-users`,item).then(response => {
                    // console.log("remove user",response)
                    commit('SET_REMOVE_USERS_FROM_GROUP_LOADING', false)
                    resolve(response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_REMOVE_USERS_FROM_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_REMOVE_USERS_FROM_GROUP_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    addUsersInGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_ADD_USERS_IN_GROUP_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/add-users`,item ).then(response => {
                    // console.log("add users", response)
                    commit('SET_ADD_USERS_IN_GROUP_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_USERS_IN_GROUP',response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ADD_USERS_IN_GROUP_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_ADD_USERS_IN_GROUP_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    deleteGrouAndMoveUserToOtherGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_GROUP_DELETE_AND_MOVE_USERS_TO_OTHERS_GROUPS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/delete-move`,item).then(response => {
                    commit('SET_GROUP_DELETE_AND_MOVE_USERS_TO_OTHERS_GROUPS_LOADING', false)
                    resolve(response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_DELETE_AND_MOVE_USERS_TO_OTHERS_GROUPS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_DELETE_AND_MOVE_USERS_TO_OTHERS_GROUPS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    deleteGrouWithoutMoveUserToOtherGroupApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_GROUP_DELETE_WITHOUT_MOVE_USERS_TO_OTHERS_GROUPS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/groups/${item.group_id}/delete`,item).then(response => {
                    commit('SET_GROUP_DELETE_WITHOUT_MOVE_USERS_TO_OTHERS_GROUPS_LOADING', false)
                    resolve(response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_DELETE_WITHOUT_MOVE_USERS_TO_OTHERS_GROUPS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_DELETE_WITHOUT_MOVE_USERS_TO_OTHERS_GROUPS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    addGroupPermissionsApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_ADD_GROUP_PERMISSIONS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/group-permissions`,item).then(response => {
                    commit('SET_ADD_GROUP_PERMISSIONS_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_GROUP_PERMISSIONS',response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ADD_GROUP_PERMISSIONS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_ADD_GROUP_PERMISSIONS_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    updateGroupPermissionsApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_UPDATE_GROUP_PERMISSIONS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.put(`${poBaseUrl}/group-permissions`,item).then(response => {
                    commit('SET_UPDATE_GROUP_PERMISSIONS_LOADING', false)
                    resolve(response.data)
                    // commit('SET_UPDATE_GROUP_PERMISSIONS', response)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_UPDATE_GROUP_PERMISSIONS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_UPDATE_GROUP_PERMISSIONS_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    fetchGroupPermissions: async ({ commit }, group_id) => {
        let attempt = false
        commit('SET_GROUP_PERMISSIONS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/group-permissions/${group_id}`).then(response => {
                    // console.log("permissions", response)
                    commit('SET_GROUP_PERMISSIONS_LOADING', false)
                    resolve(response.data)
                    commit('SET_GROUP_PERMISSIONS', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_PERMISSIONS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_GROUP_PERMISSIONS_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    fetchAllDefaultGrouppermissions: async ({ commit }) => {
        let attempt = false
        commit('SET_ALL_DEFAULT_GROUP_TEMPLATES_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/group-templates`).then(response => {
                    // console.log("fetchAllDefaultGrouppermissions",response.data)
                    commit('SET_ALL_DEFAULT_GROUP_TEMPLATES_LOADING', false)
                    resolve(response.data)
                    commit('SET_ALL_DEFAULT_GROUP_TEMPLATES', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ALL_DEFAULT_GROUP_TEMPLATES_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_ALL_DEFAULT_GROUP_TEMPLATES_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchGroupTemplatePermissions: async ({ commit }, group_template_id) => {
        let attempt = false
        commit('SET_GROUP_TEMPLATES_PERMISSIONS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/group-permissions-templates/${group_template_id}`).then(response => {
                    // console.log("group_template_id response", response.data)
                    commit('SET_GROUP_TEMPLATES_PERMISSIONS_LOADING', false)
                    resolve(response.data)
                    commit('SET_GROUP_TEMPLATES_PERMISSIONS', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_TEMPLATES_PERMISSIONS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_TEMPLATES_PERMISSIONS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchAllModules: async ({ commit }) => {
        let attempt = false
        commit('SET_ALL_MODULES_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/modules`).then(response => {
                    // console.log("Fetch_ALL_MODULES", response)
                    commit('SET_ALL_MODULES_LOADING', false)
                    resolve(response.data)
                    commit('SET_ALL_MODULES', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ALL_MODULES_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_ALL_MODULES_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchGroupDetails: async ({ commit },group_id) => {
        let attempt = false
        commit('SET_GROUP_DETAILS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/groups/${group_id}`).then(response => {
                    // console.log("SET_GROUP_DETAILS", response)
                    commit('SET_GROUP_DETAILS_LOADING', false)
                    resolve(response.data)
                    commit('SET_GROUP_DETAILS', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_GROUP_DETAILS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_GROUP_DETAILS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    // import Name Tab
    fetchAllImportNames: async ({ commit }, id) => {
        let attempt = false
        commit('SET_ALL_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/v2/import-names/${id}`).then(response => {
                    // console.log("all import", response)
                    commit('SET_ALL_IMPORT_NAME_LOADING', false)
                    resolve(response.data)
                    commit('SET_ALL_IMPORT_NAME', response.data.results)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ALL_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_ALL_IMPORT_NAME_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchAllImportNamesDropdown: async ({ commit }, id) => {
        let attempt = false
        commit('SET_ALL_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`${poBaseUrl}/v2/import-names/${id}?no_pagination=true`).then(response => {
                    // console.log("all import", response)
                    commit('SET_ALL_IMPORT_NAME_LOADING', false)
                    resolve(response.data)
                    commit('SET_ALL_IMPORT_NAME_DROPDOWN', response.data.results)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_ALL_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_ALL_IMPORT_NAME_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    fetchImportNameDetails: async ({ commit }, id) => {
        let attempt = false
        commit('SET_IMPORT_NAME_DETAILS_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.get(`https://postaging.shifl.com/v2/import-name/${id}`).then(response => {
                    // console.log("SET_GROUP_DETAILS", response)
                    commit('SET_IMPORT_NAME_DETAILS_LOADING', false)
                    resolve(response.data)
                    commit('SET_IMPORT_NAME_DETAILS', response.data.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_IMPORT_NAME_DETAILS_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.error !== 'undefined') {
                            commit("SET_IMPORT_NAME_DETAILS_LOADING", false)
                            reject(err.error)
                        }
                    })
            }
            proceed()
        })
    },
    updateImportNameApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_UPDATE_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/v2/import-name/update/${item.id}`, item.bodyFormData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }).then(response => {
                    commit('SET_UPDATE_IMPORT_NAME_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_GROUP_PERMISSIONS', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_UPDATE_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_UPDATE_IMPORT_NAME_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    createImportNameApi: async ({ commit }, item) => {
        let attempt = false
        commit('SET_CREATE_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.post(`${poBaseUrl}/v2/import-name/new`, item,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                }).then(response => {
                    // console.log('create',response)
                    if (response.status == 200){
                        if(response.data.status == 400){
                            reject(response.data.errors)
                        }
                        resolve(response.data)
                        commit('SET_ADD_GROUP_PERMISSIONS', response.data)
                    }
                    commit('SET_CREATE_IMPORT_NAME_LOADING', false)
                    
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_CREATE_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_CREATE_IMPORT_NAME_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
    deleteImportNameApi: async ({ commit }, id) => {
        let attempt = false
        commit('SET_DELETE_IMPORT_NAME_LOADING', true)
        return new Promise((resolve, reject) => {
            function proceed() {
                axios.delete(`${poBaseUrl}/v2/import-name/${id}`).then(response => {
                    commit('SET_DELETE_IMPORT_NAME_LOADING', false)
                    resolve(response.data)
                    commit('SET_ADD_GROUP_PERMISSIONS', response.data)
                })
                    .catch(err => {
                        if (typeof err.message !== 'undefined') {
                            if (!attempt) {
                                attempt = true
                                let t = setInterval(() => {
                                    if (!store.getters.getIsRefreshing) {
                                        proceed()
                                        clearInterval(t)
                                    }
                                }, 300)
                            } else {
                                commit("SET_DELETE_IMPORT_NAME_LOADING", false)
                                reject(err)
                            }
                        }

                        if (typeof err.message !== 'undefined') {
                            commit("SET_DELETE_IMPORT_NAME_LOADING", false)
                            reject(err.message)
                        }
                    })
            }
            proceed()
        })
    },
}

const mutations = {
    SET_ADD_GROUP_LOADING: (state, payload) => {
        state.addGroupLoading = payload
    },
    SET_ADD_GROUP: (state, payload) => {
        state.addGroup = payload
    },
    SET_EDIT_GROUP_LOADING: (state, payload) => {
        state.editGroupLoading = payload
    },
    SET_EDIT_GROUP: (state, payload) => {
        state.editGroup = payload
    },
    SET_DELETE_GROUP_LOADING: (state, payload) => {
        state.deleteGroupLoading = payload
    },
    SET_GROUP_USERS_LOADING: (state, payload) => {
        state.groupUsersLoading = payload
    },
    SET_GROUP_USERS: (state, payload) => {
        state.groupUsers = payload
    },
    SET_GROUP_WITH_USERS_AND_PERMISSIONS: (state, payload) => {
        state.groupWithUsersAndPermissions = payload
    },
    SET_GROUP_WITH_USERS_AND_PERMISSIONS_LOADING: (state, payload) => {
        state.groupWithUsersAndPermissionsLoading = payload
    },
    SET_DELIVERY_LOCATIONS: (state, payload) => {
        state.groupDeliveryLocations = payload
    },
    SET_REMOVE_USERS_FROM_GROUP_LOADING: (state, payload) => {
        state.removeUsersFromGroupLoading = payload
    },
    SET_ADD_USERS_IN_GROUP_LOADING: (state, payload) => {
        state.addUsersInGroupLoading = payload
    },
    SET_ADD_USERS_IN_GROUP: (state, payload) => {
    state.addUsersInGroup = payload
    },
    SET_GROUP_DELETE_AND_MOVE_USERS_TO_OTHERS_GROUPS_LOADING: (state, payload) => {
        state.groupDeleteAndMoveUserToOtherGroupLoading = payload
    },
    SET_GROUP_DELETE_WITHOUT_MOVE_USERS_TO_OTHERS_GROUPS_LOADING: (state, payload) => {
        state.groupDeleteWithoutMoveUserToOtherGroupLoading = payload
    },
    SET_ADD_DELIVERY_GROUP_LOADING: (state, payload) => {
        state.addDeliveryLocationLoading = payload
    },
    SET_ADD_DELIVERY_GROUP: (state, payload) => {
        state.addDeliveryLocation = payload
    },
    
    SET_ALL_DELIVERY_LOCATION_LOADING: (state, payload) => {
        state.allDeliveryLocationLoading = payload
    },

    SET_ALL_DELIVERY_LOCATION: (state, payload) => {
        state.allDeliveryLocation = payload
    },

    SET_SINGLE_DELIVERY_LOCATION_LOADING: (state, payload) => {
        state.singleDeliveryLocationLoading = payload
    },
    
    SET_SINGLE_DELIVERY_LOCATION: (state, payload) => {
        state.singleDeliveryLocation = payload
    },
    
    SET_ADD_GROUP_PERMISSIONS_LOADING: (state, payload) => {
        state.addGroupPermissionsLoading = payload
    },
    SET_ADD_GROUP_PERMISSIONS: (state, payload) => {
        state.addGroupPermissions = payload
    },
    SET_UPDATE_GROUP_PERMISSIONS_LOADING: (state, payload) => {
        state.updateGroupPermissionsLoading = payload
    },
    SET_GROUP_PERMISSIONS_LOADING: (state, payload) => {
        state.groupPermissionsLoading = payload
    },
    SET_GROUP_PERMISSIONS: (state, payload) => {
        state.groupPermissions = payload
    },
    SET_ALL_DEFAULT_GROUP_TEMPLATES: (state, payload) => {
        state.allDefaultGroupTemplates = payload
    },
    SET_ALL_DEFAULT_GROUP_TEMPLATES_LOADING: (state, payload) => {
        state.allDefaultGroupTemplatesLoading = payload
    },
    SET_GROUP_TEMPLATES_PERMISSIONS: (state, payload) => {
        state.groupTemplatePermissions = payload
    },
    SET_GROUP_TEMPLATES_PERMISSIONS_LOADING: (state, payload) => {
        state.groupTemplatePermissionsLoading = payload
    },
    SET_ALL_MODULES_LOADING: (state, payload) => {
        state.allModulesLoading = payload
    },
    SET_ALL_MODULES: (state, payload) => {
        state.allModules = payload
    },
    setGroupUserDataEmpty:(state,payload) =>{
        state.groupUsers = payload
    },
    setGroupPermissionsEmpty:(state,payload)=>{
        state.groupPermissions = payload
    },
    setGroupDetailsDataEmpty: (state, payload) => {
        state.groupDetails = payload
    },
    SET_GROUP_DETAILS_LOADING: (state, payload) => {
        state.groupDetailsLoading = payload
    },
    SET_GROUP_DETAILS: (state, payload) => {
        state.groupDetails = payload
    },
    setUserGroupAsASelectiveTab: (state, payload) => {
        state.UserAndUserGroupActveTab = payload
    },
    emptyAddUserGroupData: (state, payload) => {
        state.addGroup = payload
    },
    
    SET_ALL_IMPORT_NAME_LOADING: (state, payload) => {
        state.allImportNameLoading = payload
    },
    SET_ALL_IMPORT_NAME: (state, payload) => {
        state.allImportName = payload
    },
    SET_ALL_IMPORT_NAME_DROPDOWN: (state, payload) => {
        state.allImportNameDropdown = payload
    },
    SET_IMPORT_NAME_DETAILS_LOADING: (state, payload) => {
        state.importNameDetailsLoading = payload
    },
    SET_IMPORT_NAME_DETAILS: (state, payload) => {
        state.importNameDetails = payload
    },
    SET_UPDATE_IMPORT_NAME_LOADING: (state, payload) => {
        state.updateImportNameLoading = payload
    },
    SET_UPDATE_DELIVERY_LOCATION_LOADING: (state, payload) => {
        state.updateDeliveryLocationLoading = payload
    },
    SET_UPDATE_DELIVERY_LOCATION: (state, payload) => {
        state.updateDeliveryLocation = payload
    },
    SET_CREATE_IMPORT_NAME_LOADING: (state, payload) => {
        state.createImportNameLoading = payload
    },
    SET_DELETE_IMPORT_NAME_LOADING: (state, payload) => {
        state.deleteImportNameLoading = payload
    },
    SET_DELETE_DELIVERY_LOCATION_LOADING: (state, payload) => {
        state.deleteDeliveryLocationLoading = payload
    },
    SET_DELETE_DELIVERY_LOCATION: (state, payload) => {
        state.deleteDeliveryLocation = payload
    },

    SET_DELETE_CONTACT_LOADING: (state, payload) => {
        state.deleteContactLoading = payload
    },
    SET_DELETE_CONTACT: (state, payload) => {
        state.deleteContact = payload
    },

    SET_DEACTIVATE_ACCOUNT_LOADING: (state, payload) => {
        state.deactivateAccountLoading = payload
    },
    SET_DEACTIVATE_ACCOUNT: (state, payload) => {
        state.deactivateAccount = payload
    },
    SET_REACTIVATE_ACCOUNT_LOADING: (state, payload) => {
        state.reactivateAccountLoading = payload
    },
    SET_REACTIVATE_ACCOUNT: (state, payload) => {
        state.reactivateAccount = payload
    },
    SET_DELIVERY_LOCATIONS_LOADING: (state, payload) => {
        state.deliveryLocationLoading = payload
    },
    setActiveTabOfCompanyProfileMutation:(state,payload)=>{
        state.activeTabOfCompanyProfile = payload
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}