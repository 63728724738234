/** @format */

import axios from "axios";
import ShipmentModel from "../../models/ShipmentModel";
import store from "../";

const betaBaseUrl = process.env.VUE_APP_BASE_URL;
const state = {
	consignees: [],
	shippers: [],
	containerSizes: [],
	consigneesLoading: false,
	shippersLoading: false,
	connectedConsignee: 0,
	connectedConsigneeLoading: false,
	connectedShipper: 0,
	connectedShipperLoading: false,
	createBookingShipmentLoading: false,
	containerSizesLoading: false,
	shipmentDetails: null,
	shipmentDetailsLoading: false,
	checkingMblNumber: false,
	shipper_details_info: null,
	buyer_details_info: null,
	shipmentCustomerDocuments: [],
	deleteShipmentCustomerDocumentsLoading: false,
	setMBLNumberData: null,
	defaultRequirements: [],
	requiredContainers: [],
	createNewBookingShipmentLoading: false,
	createConsolidatedShipment: null,
	createConsolidatedShipmentLoading: false,
	updateUserEmailsLoading: false,
	generateBookingLinkLoading: false,
	updateBookingLinkLoading: false,
	deleteBookingLinkLoading: false,
	bookingLinkData: null,
	bookingLinkDataLoading: false,
	bookingOrdersData: null,
	bookingOrdersDataLoading: false,
	exisitingBookingData: null,
	exisitingBookingDataLoading: false,
	bookingShipmentDataList: [],
	bookingShipmentDataListLoading: false,
};

const getters = {
	getShippersDetailsInfo: (state) => state.shipper_details_info,
	getBuyersDetailsInfo: (state) => state.buyer_details_info_info,
	getConsignees: (state) => state.consignees,
	getShipmentDetails: (state) => state.shipmentDetails,
	getShipmentDetailsLoading: (state) => state.shipmentDetailsLoading,
	getShippers: (state) => state.shippers,
	getContainerSizes: (state) => state.containerSizes,
	getCreateBookingShipmentLoading: (state) =>
		state.createBookingShipmentLoading,
	getContainerSizesLoading: (state) => state.containerSizesLoading,
	getConnectedShipper: (state) => state.connectedShipper,
	getConnectedConsignee: (state) => state.connectedConsignee,
	getConnectedShipperLoading: (state) => state.connectedShipperLoading,
	getConnectedConsigneeLoading: (state) => state.connectedConsigneeLoading,
	getCheckingMblNumber: (state) => state.checkingMblNumber,
	getShipmentCustomerDocuments: (state) => state.shipmentCustomerDocuments,
	getDeleteShipmentCustomerDocumentsLoading: (state) =>
		state.deleteShipmentCustomerDocumentsLoading,
	getMBLNumberData: (state) => state.setMBLNumberData,
	getDefaultRequirements: (state) => state.defaultRequirements,
	getRequiredContainers: (state) => state.requiredContainers,
	getCreateNewBookingShipmentLoading: (state) =>
		state.createNewBookingShipmentLoading,
	getConsolidatedShipment: (state) => state.createConsolidatedShipment,
	getConsolidatedShipmentLoading: (state) =>
		state.createConsolidatedShipmentLoading,
	getUpdateUserEmailsLoading: (state) => state.updateUserEmailsLoading,
	getGenerateBookingLinkLoading: (state) => state.generateBookingLinkLoading,
	getUpdateBookingLinkLoading: (state) => state.updateBookingLinkLoading,
	getDeleteBookingLinkLoading: (state) => state.deleteBookingLinkLoading,
	getbookingLinkData: (state) => state.bookingLinkData,
	getbookingLinkDataLoading: (state) => state.bookingLinkDataLoading,
	getBookingOrdersData: (state) => state.bookingOrdersData,
	getBookingOrdersDataLoading: (state) => state.bookingOrdersDataLoading,
	getExisitingBookingData: (state) => state.exisitingBookingData,
	getExisitingBookingDataLoading: (state) => state.exisitingBookingDataLoading,
	getBookingShipmentDataList: (state) => state.bookingShipmentDataList,
	getBookingShipmentDataListLoading: (state) =>
		state.bookingShipmentDataListLoading,
};

const mutations = {
	SET_SHIPPER_DETAILS_INFO: (state, payload) => {
		state.shipper_details_info = payload;
	},
	SET_BUYER_DETAILS_INFO: (state, payload) => {
		state.buyer_details_info = payload;
	},
	SET_CHECKING_MBL_NUMBER: (state, payload) => {
		state.checkingMblNumber = payload;
	},
	SET_CONSIGNEES_LOADING: (state, payload) => {
		state.consigneesLoading = payload;
	},
	SET_CONTAINER_SIZES_LOADING: (state, payload) => {
		state.containerSizesLoading = payload;
	},
	SET_CONTAINER_SIZES: (state, payload) => {
		state.containerSizes = payload;
	},
	SET_CONSIGNEES: (state, payload) => {
		state.consignees = payload;
	},
	SET_CONNECTED_CONSIGNEE_LOADING: (state, payload) => {
		state.connectedConsigneeLoading = payload;
	},
	SET_CONNECTED_CONSIGNEE: (state, payload) => {
		state.connectedConsignee = payload;
	},
	SET_CONNECTED_SHIPPER_LOADING: (state, payload) => {
		state.connectedShipperLoading = payload;
	},
	SET_CONNECTED_SHIPPER: (state, payload) => {
		state.connectedShipper = payload;
	},
	SET_SHIPPERS_LOADING: (state, payload) => {
		state.shippersLoading = payload;
	},
	SET_SHIPPERS: (state, payload) => {
		state.shippers = payload;
	},
	SET_CREATE_BOOKING_SHIPMENT_LOADING: (state, payload) => {
		state.createBookingShipmentLoading = payload;
	},
	SET_SHIPMENT_DETAILS: (state, payload) => {
		state.shipmentDetails = payload;
	},
	SET_SHIPMENT_DETAILS_LOADING: (state, payload) => {
		state.shipmentDetailsLoading = payload;
	},
	SET_SHIPMENT_CUSTOMER_DOCUMENTS: (state, payload) => {
		state.shipmentCustomerDocuments = payload;
	},
	SET_SHIPMENT_CUSTOMER_DOCUMENTS_LOADING: (state, payload) => {
		state.deleteShipmentCustomerDocumentsLoading = payload;
	},
	SET_MBL_NUMBER_DATA: (state, payload) => {
		state.setMBLNumberData = payload;
	},
	SET_DEFAULT_REQUIREMENTS_VALUE: (state, payload) => {
		state.defaultRequirements = payload;
	},
	SET_REQUIRED_CONTAINERS_VALUE: (state, payload) => {
		state.requiredContainers = payload;
	},
	SET_CREATE_BOOKING_SHIPMENT_VALUE: (state, payload) => {
		state.createBookingShipment = payload;
	},
	SET_CREATE_NEW_BOOKING_SHIPMENT_LOADING: (state, payload) => {
		state.createNewBookingShipmentLoading = payload;
	},
	SET_CONSOLIDATED_SHIPMENT_VALUE: (state, payload) => {
		state.createConsolidatedShipment = payload;
	},
	SET_CREATE_CONSOLIDATED_SHIPMENT_LOADING: (state, payload) => {
		state.createConsolidatedShipmentLoading = payload;
	},
	SET_UPDATE_USER_EMAILS_LOADING: (state, payload) => {
		state.updateUserEmailsLoading = payload;
	},
	SET_GENERATE_BOOKING_LINK_LOADING: (state, payload) => {
		state.generateBookingLinkLoading = payload;
	},
	SET_UPDATE_BOOKING_LINK_LOADING: (state, payload) => {
		state.updateBookingLinkLoading = payload;
	},
	SET_DELETE_BOOKING_LINK_LOADING: (state, payload) => {
		state.deleteBookingLinkLoading = payload;
	},
	SET_BOOKING_LINK_DATA_VALUE: (state, payload) => {
		state.bookingLinkData = payload;
	},
	SET_BOOKING_LINK_DATA_LOADING: (state, payload) => {
		state.bookingLinkDataLoading = payload;
	},
	SET_BOOKING_ORDERS_DATA_VALUE: (state, payload) => {
		state.bookingOrdersData = payload;
	},
	SET_BOOKING_ORDERS_DATA_LOADING: (state, payload) => {
		state.bookingOrdersDataLoading = payload;
	},
	SET_EXISITING_BOOKING_DATA_VALUE: (state, payload) => {
		state.exisitingBookingData = payload;
	},
	SET_EXISITING_BOOKING_DATA_LOADING: (state, payload) => {
		state.exisitingBookingDataLoading = payload;
	},
	SET_BOOKING_SHIPMENT_VALUE: (state, payload) => {
		state.bookingShipmentDataList = payload;
	},
	SET_BOOKING_SHIPMENT_DATA_LOADING: (state, payload) => {
		state.bookingShipmentDataListLoading = payload;
	},
};

/* factory for fetching resource */
function fetchResource(
	{ resources, loading, loaded, url, responseKey },
	commit
) {
	commit(loading, true);
	let attempt = false;

	return new Promise((resolve, reject) => {
		let limit = 1;
		let start = 0;
		url =
			typeof url == "undefined"
				? `/v2/${resources}?no_pagination=1&per_page=1000`
				: url;
		function proceed() {
			axios
				.get(url)
				.then((res) => {
					commit(loading, false);
					if (
						typeof res.data !== "undefined" &&
						typeof res.data[responseKey] !== "undefined"
					) {
						commit(loaded, res.data[responseKey]);
						resolve(res.data[responseKey]);
					} else {
						commit(loaded, res.data);
						resolve(res.data);
					}
				})
				.catch((err) => {
					if (typeof err.message !== "undefined") {
						if (!attempt) {
							attempt = true;
							let t = setInterval(() => {
								if (!store.getters.getIsRefreshing) {
									if (start == limit) {
										store
											.dispatch("refreshToken")
											.then(() => {
												attempt = false;
												start = 0;
												proceed();
											})
											.catch((e) => {
												console.log(e);
												commit(loading, false);
												reject(err);
											});
									} else {
										start++;
										proceed();
										attempt = false;
									}
									clearInterval(t);
								}
							}, 300);
						} else {
							commit(loading, false);
							reject(err);
						}
					}
				});
		}
		proceed();
	});
}

const actions = {
	checkMblNumber: async ({ commit }, mbl_num) => {
		commit("SET_CHECKING_MBL_NUMBER", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.checkMblNumber(mbl_num)
					.then((res) => {
						commit("SET_CHECKING_MBL_NUMBER", false);
						commit("SET_MBL_NUMBER_DATA", res.data);
						resolve(res);
					})
					.catch((e) => {
						commit("SET_CHECKING_MBL_NUMBER", false);
						reject(e);
					});
			}
			proceed();
		});
	},
	fetchBuyerSeller: async ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.fetchBuyerSupplier(params)
					.then((res) => {
						if (params.role === "shipper") {
							commit("SET_SHIPPER_DETAILS_INFO", res.data);
						} else {
							commit("SET_BUYER_DETAILS_INFO", res.data);
						}
						resolve(res.data);
					})
					.catch((e) => {
						reject(e);
					});
			}
			proceed();
		});
	},
	fetchShipmentDetails: async ({ commit }, id) => {
		commit("SET_SHIPMENT_DETAILS_LOADING", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.fetchShipmentDetails(id)
					.then((res) => {
						commit("SET_SHIPMENT_DETAILS_LOADING", false);
						commit("SET_SHIPMENT_DETAILS", res);
						resolve(res);
					})
					.catch((err) => {
						commit("SET_SHIPMENT_DETAILS_LOADING", false);
						reject(err);
					});
			}
			proceed();
		});
	},
	fetchContainerSizes: async ({ commit }) => {
		return new Promise((resolve, reject) => {
			fetchResource(
				{
					url: "/container-sizes",
					loading: "SET_CONTAINER_SIZES_LOADING",
					loaded: "SET_CONTAINER_SIZES",
					responseKey: "data",
				},
				commit
			)
				.then((res) => {
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	getPrefilledConsignee: async ({ commit }) => {
		return new Promise((resolve, reject) => {
			fetchResource(
				{
					url: "/users/connected-consignee",
					loading: "SET_CONNECTED_CONSIGNEE_LOADING",
					loaded: "SET_CONNECTED_CONSIGNEE",
					responseKey: "data",
				},
				commit
			)
				.then((res) => {
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	getPrefilledShipper: async ({ commit }) => {
		return new Promise((resolve, reject) => {
			fetchResource(
				{
					url: "/users/connected-shipper",
					loading: "SET_CONNECTED_SHIPPER_LOADING",
					loaded: "SET_CONNECTED_SHIPPER",
					responseKey: "data",
				},
				commit
			)
				.then((res) => {
					resolve(res);
				})
				.catch((e) => {
					reject(e);
				});
		});
	},
	getConsigneeOptions: async ({ commit }) => {
		fetchResource(
			{
				resources: "buyers",
				loading: "SET_CONSIGNEES_LOADING",
				loaded: "SET_CONSIGNEES",
				responseKey: "data",
			},
			commit
		);
	},
	createShipment: async ({ commit }, payload) => {
		commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.createBookingShipment(payload)
					.then((res) => {
						commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", false);
						resolve(res);
					})
					.catch((err) => {
						commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", false);
						reject(err);
					});
			}
			proceed();
		});
	},
	saveDraftBookingForm: async ({ commit }, payload) => {
		commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.saveDraftBookingForm(payload)
					.then((res) => {
						commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", false);
						resolve(res);
					})
					.catch((err) => {
						commit("SET_CREATE_BOOKING_SHIPMENT_LOADING", false);
						reject(err);
					});
			}
			proceed();
		});
	},
	getShipperOptions: async ({ commit }) => {
		fetchResource(
			{
				resources: "suppliers",
				loading: "SET_SHIPPERS_LOADING",
				loaded: "SET_SHIPPERS",
				responseKey: "data",
			},
			commit
		);
	},
	fetchShipmentCustomerDocuments: async ({ commit }, id) => {
		commit("SET_SHIPMENT_DETAILS_LOADING", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.fetchShipmentCustomerDocuments(id)
					.then((res) => {
						commit("SET_SHIPMENT_DETAILS_LOADING", false);
						commit("SET_SHIPMENT_CUSTOMER_DOCUMENTS", res);
						resolve(res);
					})
					.catch((err) => {
						commit("SET_SHIPMENT_DETAILS_LOADING", false);
						reject(err);
					});
			}
			proceed();
		});
	},
	deleteShipmentCustomerDocuments: async ({ commit }, id) => {
		commit("SET_SHIPMENT_CUSTOMER_DOCUMENTS_LOADING", true);
		return new Promise((resolve, reject) => {
			function proceed() {
				let sm = new ShipmentModel(betaBaseUrl);
				sm.deleteShipmentCustomerDocuments(id)
					.then((res) => {
						commit("SET_SHIPMENT_CUSTOMER_DOCUMENTS_LOADING", false);
						resolve(res);
					})
					.catch((err) => {
						commit("SET_SHIPMENT_CUSTOMER_DOCUMENTS_LOADING", false);
						reject(err);
					});
			}
			proceed();
		});
	},
	fetchDefaultRequirements: async ({ commit }, customer_id) => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${betaBaseUrl}/customer/${customer_id}/default-requirements`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_DEFAULT_REQUIREMENTS_VALUE", res.data.services);
							resolve(res.data.services);
						}
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	calculateRequiredContainers: async ({ commit }, params) => {
		return new Promise((resolve, reject) => {
			let buildUrl = {
				url: `${betaBaseUrl}/v2/calculate-required-containers/${params.cbm}`,
				method: "get",
				cancelToken: params.cancelToken,
			};
			buildUrl.cancelToken = params.cancelToken;

			axios(buildUrl)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_REQUIRED_CONTAINERS_VALUE", res.data);
							resolve(res.data);
						}
					}
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
	createNewBookingShipment: async ({ commit }, payload) => {
		commit("SET_CREATE_NEW_BOOKING_SHIPMENT_LOADING", true);
		return await new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/shipment/create-consolidation-booking`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_CREATE_NEW_BOOKING_SHIPMENT_LOADING", false);
							resolve(res.data);
						}
					}
				})
				.catch((err) => {
					console.error("Error fetching data:", err);
					commit("SET_CREATE_NEW_BOOKING_SHIPMENT_LOADING", false);
					reject(err);
				});
		});
	},
	createConsolidatedShipment: async ({ commit }, payload) => {
		commit("SET_CREATE_CONSOLIDATED_SHIPMENT_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/shipment/consolidate`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_CREATE_CONSOLIDATED_SHIPMENT_LOADING", false);
							commit("SET_CONSOLIDATED_SHIPMENT_VALUE", res.data);
							resolve(res.data);
						}
					}
				})
				.catch((err) => {
					commit("SET_CREATE_CONSOLIDATED_SHIPMENT_LOADING", false);
					reject(err);
				});
		});
	},
	updateUserEmails: async ({ commit }, payload) => {
		commit("SET_UPDATE_USER_EMAILS_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/v2/update-user-email`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_UPDATE_USER_EMAILS_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_UPDATE_USER_EMAILS_LOADING", false);
					reject(err);
				});
		});
	},
	generateBookingLink: async ({ commit }, payload) => {
		commit("SET_GENERATE_BOOKING_LINK_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/booking-link`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_GENERATE_BOOKING_LINK_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_GENERATE_BOOKING_LINK_LOADING", false);
					reject(err);
				});
		});
	},
	updateBookingLink: async ({ commit }, payload) => {
		commit("SET_UPDATE_BOOKING_LINK_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.put(`${betaBaseUrl}/booking-link/${payload.token_key}`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_UPDATE_BOOKING_LINK_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_UPDATE_BOOKING_LINK_LOADING", false);
					reject(err);
				});
		});
	},
	deleteBookingLink: async ({ commit }, id) => {
		commit("SET_DELETE_BOOKING_LINK_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.delete(`${betaBaseUrl}/booking-link/${id}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_DELETE_BOOKING_LINK_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_DELETE_BOOKING_LINK_LOADING", false);
					reject(err);
				});
		});
	},
	fetchBookingLinkData: async ({ commit }, payload) => {
		commit("SET_BOOKING_LINK_DATA_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/get-booking-invite-data`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_BOOKING_LINK_DATA_LOADING", false);
							commit("SET_BOOKING_LINK_DATA_VALUE", res?.data?.data);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_BOOKING_LINK_DATA_LOADING", false);
					reject(err);
				});
		});
	},
	fetchBookingOrdersData: async ({ commit }, payload) => {
		commit("SET_BOOKING_ORDERS_DATA_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/v2/purchase-order/shipment`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_BOOKING_ORDERS_DATA_LOADING", false);
							commit("SET_BOOKING_ORDERS_DATA_VALUE", res?.data?.data);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_BOOKING_ORDERS_DATA_LOADING", false);
					reject(err);
				});
		});
	},
	addToExisitingShipment: async ({ commit }, { shipment_id, payload }) => {
		commit("SET_EXISITING_BOOKING_DATA_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.put(
					`${betaBaseUrl}/v2/order/add-existing-shipment/${shipment_id}`,
					payload
				)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_EXISITING_BOOKING_DATA_LOADING", false);
							commit("SET_EXISITING_BOOKING_DATA_VALUE", res?.data?.data);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_EXISITING_BOOKING_DATA_LOADING", false);
					reject(err);
				});
		});
	},
	fetchBookingShipments: async ({ commit }, type) => {
		commit("SET_BOOKING_SHIPMENT_DATA_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.get(`${betaBaseUrl}/existing-shipments-for-po-so?orderType=${type}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_BOOKING_SHIPMENT_DATA_LOADING", false);
							commit("SET_BOOKING_SHIPMENT_VALUE", res.data);
							resolve(res.data);
						}
					}
				})
				.catch((err) => {
					commit("SET_BOOKING_SHIPMENT_DATA_LOADING", false);
					reject(err);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
