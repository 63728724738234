/** @format */

import axios from "axios";

const state = {
	new_shipment_details: [],
	new_shipment_details_loading: false,
	update_shipment_warehouse_loading: false,
	delete_shipment_warehouse_loading: false,
	shipment_tracking_details_loading: false,
	shipment_tracking_details: [],
};

const getters = {
	getNewShipmentDetails: (state) => state.new_shipment_details,
	getNewShipmentDetailsLoading: (state) => state.new_shipment_details_loading,
	getShipmentWarehouseLoading: (state) =>
		state.update_shipment_warehouse_loading,
	getDeleteShipmentWarehouseLoading: (state) =>
		state.delete_shipment_warehouse_loading,
	getShipmentTrackingDetails: (state) => state.shipment_tracking_details,
	getShipmentTrackingDetailsLoading: (state) =>
		state.update_shipment_warehouse_loading,
};

const actions = {
	fetchNewShipmentDetails: async ({ commit }, shipment_id) => {
		commit("SET_NEW_SHIPMENTS_DETAILS_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.get(`/v2/shipment-detail/${shipment_id}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_NEW_SHIPMENTS_DETAILS_LOADING", false);
							commit("SET_NEW_SHIPMENTS_DETAILS", res.data.data);
							resolve(res.data.data);
						}
					}
				})
				.catch((err) => {
					commit("SET_NEW_SHIPMENTS_DETAILS_LOADING", false);
					reject(err);
				});
		});
	},
	editWareHouseLocation: async ({ commit }, payload) => {
		commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`v2/shipment/delivery-location/${payload.shipment_id}`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", false);
					reject(err);
				});
		});
	},
	deleteShipmentWareHouseLocation: async ({ commit }, shipment_id) => {
		commit("SET_DELETE_WAREHOUSE_LOCATION_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.delete(`v2/shipment/delivery-location/${shipment_id}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_DELETE_WAREHOUSE_LOCATION_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_DELETE_WAREHOUSE_LOCATION_LOADING", false);
					reject(err);
				});
		});
	},
	addWareHouseLocation: async ({ commit }, payload) => {
		commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`v2/shipment-delivery-location`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_UPDATE_WAREHOUSE_LOCATION_LOADING", false);
					reject(err);
				});
		});
	},
	fetchShipmentTrackingDetails: async ({ commit }, payload) => {
		let { mbl_num, shipment_id } = payload;
		commit("SET_TRACKING_DETAILS_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.get(
					`/tracking-details/${shipment_id}?mbl_num=${mbl_num}`
				)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_TRACKING_DETAILS_LOADING", false);
							commit("SET_SHIPMENTS_TRACKING_DETAILS", res.data.data);
							resolve(res.data.data);
						}
					}
				})
				.catch((err) => {
					commit("SET_TRACKING_DETAILS_LOADING", false);
					reject(err);
				});
		});
	},
};

const mutations = {
	SET_NEW_SHIPMENTS_DETAILS: (state, payload) => {
		state.new_shipment_details = payload;
	},
	SET_NEW_SHIPMENTS_DETAILS_LOADING: (state, payload) => {
		state.new_shipment_details_loading = payload;
	},
	SET_UPDATE_WAREHOUSE_LOCATION_LOADING: (state, payload) => {
		state.update_shipment_warehouse_loading = payload;
	},
	SET_DELETE_WAREHOUSE_LOCATION_LOADING: (state, payload) => {
		state.delete_shipment_warehouse_loading = payload;
	},
	SET_SHIPMENTS_TRACKING_DETAILS: (state, payload) => {
		state.shipment_tracking_details = payload;
	},
	SET_TRACKING_DETAILS_LOADING: (state, payload) => {
		state.shipment_tracking_details_loading = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
