/** @format */
import axios from "axios";
const betaBaseUrl = process.env.VUE_APP_BASE_URL;
import store from "../";

const state = {
	createBookingTemplateLoading: false,
	fetchBookingTemplateLoading: false,
	updateBookingTemplateLoading: false,
	deleteBookingTemplateLoading: false,
	bookingTemplates: [],
	saveUpdateBookingLinkTemplateLoading: false,
};

const getters = {
	getCreateBookingTemplateLoading: (state) =>
		state.createBookingTemplateLoading,
	getFetchBookingTemplateLoading: (state) => state.fetchBookingTemplateLoading,
	getUpdateBookingTemplateLoading: (state) =>
		state.updateBookingTemplateLoading,
	getDeleteBookingTemplateLoading: (state) =>
		state.deleteBookingTemplateLoading,
	getBookingTemplatesList: (state) => state.bookingTemplates,
	getSaveUpdateBookingLinkTemplateLoading: (state) =>
		state.saveUpdateBookingLinkTemplateLoading,
};

const actions = {
	createBookingTemplate: async ({ commit }, payload) => {
		commit("SET_CREATE_BOOKING_TEMPLATE_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.post(`${betaBaseUrl}/v2/booking-template`, payload)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_CREATE_BOOKING_TEMPLATE_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_CREATE_BOOKING_TEMPLATE_LOADING", false);
					reject(err);
				});
		});
	},
	fetchBookingTemplate: async ({ commit }, payload) => {
		commit("SET_FETCH_BOOKING_TEMPLATE_LOADING", true);
		let attempt = false;
		function proceed() {
			return new Promise((resolve, reject) => {
				let limit = 1;
				let start = 0;
				axios
					.get(`${betaBaseUrl}/v2/fetch-booking-template/${payload}`)
					.then((res) => {
						if (res.status === 200) {
							if (typeof res.data !== "undefined") {
								commit("SET_FETCH_BOOKING_TEMPLATE_LOADING", false);
								commit("SET_BOOKING_TEMPLATE", res.data.data);
								resolve(res.data.data);
							}
						}
					})
					.catch((err) => {
						if (typeof err.message !== "undefined") {
							if (!attempt) {
								attempt = true;
								let t = setInterval(() => {
									if (!store.getters.getIsRefreshing) {
										if (start == limit) {
											store
												.dispatch("refreshToken")
												.then(() => {
													attempt = false;
													start = 0;
													proceed();
												})
												.catch((e) => {
													console.log(e);
													commit("SET_FETCH_BOOKING_TEMPLATE_LOADING", false);
													reject(err);
												});
										} else {
											start++;
											proceed();
											attempt = false;
										}
										clearInterval(t);
									}
								}, 300);
							} else {
								commit("SET_FETCH_BOOKING_TEMPLATE_LOADING", false);
								reject(err);
							}
						}
					});
			});
		}
		proceed();
	},
	deleteBookingTemplate: async ({ commit }, payload) => {
		commit("SET_DELETE_BOOKING_TEMPLATE_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.delete(`${betaBaseUrl}/v2/delete-booking-template/${payload}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_DELETE_BOOKING_TEMPLATE_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_DELETE_BOOKING_TEMPLATE_LOADING", false);
					reject(err);
				});
		});
	},
	updateBookingTemplate: async ({ commit }, payload) => {
		commit("SET_UPDATE_BOOKING_TEMPLATE_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.put(
					`${betaBaseUrl}/v2/update-booking-template/${payload.templateId}`,
					payload
				)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_UPDATE_BOOKING_TEMPLATE_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_UPDATE_BOOKING_TEMPLATE_LOADING", false);
					reject(err);
				});
		});
	},
	saveBookingLinkTemplateData: async ({ commit }, payload) => {
		commit("SET_SAVE_UPDATE_BOOKING_LINK_TEMPLATE_LOADING", true);
		return new Promise((resolve, reject) => {
			axios
				.put(
					`${betaBaseUrl}/v2/vendor/booking-template/${payload.requestBookingToken}`,
					payload
				)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data !== "undefined") {
							commit("SET_SAVE_UPDATE_BOOKING_LINK_TEMPLATE_LOADING", false);
							resolve(res);
						}
					}
				})
				.catch((err) => {
					commit("SET_SAVE_UPDATE_BOOKING_LINK_TEMPLATE_LOADING", false);
					reject(err);
				});
		});
	},
};

const mutations = {
	SET_CREATE_BOOKING_TEMPLATE_LOADING: (state, payload) => {
		state.createBookingTemplateLoading = payload;
	},
	SET_FETCH_BOOKING_TEMPLATE_LOADING: (state, payload) => {
		state.fetchBookingTemplateLoading = payload;
	},
	SET_UPDATE_BOOKING_TEMPLATE_LOADING: (state, payload) => {
		state.updateBookingTemplateLoading = payload;
	},
	SET_DELETE_BOOKING_TEMPLATE_LOADING: (state, payload) => {
		state.deleteBookingTemplateLoading = payload;
	},
	SET_BOOKING_TEMPLATE: (state, payload) => {
		state.bookingTemplates = payload;
	},
	SET_SAVE_UPDATE_BOOKING_LINK_TEMPLATE_LOADING: (state, payload) => {
		state.saveUpdateBookingLinkTemplateLoading = payload;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
