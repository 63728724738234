import store from "@/store";
require('pusher-js')
import Echo from "laravel-echo";
import axios from "axios";
import moment from "moment";

export default new Echo({
	broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS: process.env.VUE_APP_PUSHER_TLS,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
	authorizer: (channel) => {
		return {
            authorize: (socketId, callback) => {
                axios.post(`${process.env.VUE_APP_BETA_BROADCASTING_AUTH_HOST}`, {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    console.log(error)
                    callback(true, error);
                });
            }
        };
	},
});

export function connectToCentralNotificationChannel(userId) {
    window.centralEcho.private(`customer.notification.${userId}`)
    .listen('.App\\Events\\PushNotificationEvent', e => {
        store.commit("notificationNew/ADD_NEW_MESSAGE", e.data);
        store.dispatch("notificationNew/fetchPushedNotification", e.data);
        
        const sPush = store.getters["notificationNew/getShouldPushNotify"].includes(e.data.id);			
        if (sPush) {
            store.commit("notificationNew/REMOVE_FROM_SHOULD_NOTIFY", e.data.id);
        }
        
        // avoid receiving duplicate push notifications
        setTimeout(() => {
            let lastNotificationId = localStorage.getItem('lastNotificationId') !== undefined 
                ? parseInt(localStorage.getItem('lastNotificationId')) : 0; 

            if (lastNotificationId !== parseInt(e.data.id)) {
                localStorage.setItem('lastNotificationId', parseInt(e.data.id))

                if (sPush) {
                    store.commit("notificationNew/REMOVE_FROM_SHOULD_NOTIFY", e.data.id);
                }

                if (Notification.permission === "granted" && sPush) {
                    new Notification(shipmentNotifTitle(e.data.type), {
                        body: shipmentNotifBody(e.data) ?? "You have a new notification",
                        // icon: "/favicon-shifl.ico",
                        icon: "/shifl-icon.svg",
                    });
                }
            }
        }, Math.random() * 1000);
    })
}

export function leaveCentralNotificationChannel(userId) {
    window.centralEcho.leaveChannel(`notification.${userId}`);
}

function shipmentNotifTitle(type) {
    let title = "";
    if (type === "booking_created") {
        title = "New Booking";

    } else if (type === "booking_updated") {
        title = "Booking Update";

    } else if (type === "booking_confirmed") {
        title = "Booking Confirmation";

    } else if (type === "delivery_order_sent") {
        title = "Delivery Order";

    } else if (type === "eta_changed" || type === "eta_change_alert") {
        title = "ETA Change Alert";

    } else if (type === "document_upload") {
        title = "New Document Upload";

    } else if (type === "booking_request") {
        title = "Booking Request";

    } else if (type === "consolidation_request") {
        title = "Consolidation Request";

    } else if (type === "etd_change_alert") {
        title = "ETD Change Alert";

    } else if (type === "lfd_alert") {
        title = "LFD Alert";

    } else if (type === "unreturned_container_alert") {
        title = "Unreturned Container Alert";

    } else if (type === "discharge_alert") {
        title = "Container Discharge Alert";

    } else {
        title = type === 'departure_notice_sent' ? "Departure Notice" : "Arrival Notice"
    }
    return title;
}

function shipmentNotifBody(notif) {
    let subtitle = "", pos = notif.data.po_nums, cont = notif.data.container_numbers;

    if (notif.type === "booking_created") {
        subtitle = `A new booking ID#${notif.data.shifl_ref} was issued ${pos !== null ? `for PO#${pos}` : ''}`

    } else if (notif.type === "booking_updated") {
        subtitle = `Booking ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} was updated`

    } else if (notif.type === "booking_confirmed") {
        subtitle = `Booking ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} was confirmed`

    } else if (notif.type === "delivery_order_sent") {
        subtitle = `Delivery order for ID#${notif.data.shifl_ref} | PO#${pos} | Container#${cont} was issued`

    } else if (notif.type === "eta_changed" || notif.type === "eta_change_alert") {
        subtitle = `ETA for ID#${notif.data.shifl_ref} - PO#${pos} was updated to ${formatDate(notif.data.new_eta)}`

    } else if (notif.type === "document_upload") {
        subtitle = `Shipment ID#${notif.data.shifl_ref} has new document upload`

    } else if (notif.type === "booking_request") {
        subtitle = `A new booking request with Shipment ID#${notif.data.shifl_ref} has been issued`;

    } else if (notif.type === "consolidation_request") {
        subtitle = `A new booking consolidation request with Shipment ID#${notif.data.shifl_ref} has been issued`;

    } else if (notif.type === "etd_change_alert") {
        subtitle = `ETD for ID#${notif.data.shifl_ref} - PO#${pos} was updated to ${formatDate(notif.data.new)}`

    } else if (notif.type === "lfd_alert") {
        subtitle = `LFD Alert for Shipment ID#${notif.data.shifl_ref}`

    } else if (notif.type === "discharge_alert") {
        subtitle = `Container Discharge Alert for Shipment ID#${notif.data.shifl_ref}`

    } else if (notif.type === "unreturned_container_alert") {
        subtitle = `Unreturned Container Alert for Shipment ID#${notif.data.shifl_ref}`

    } else {
        subtitle = notif.type === 'departure_notice_sent' ? 
            `Shipment ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} Departed on ${formatDate(notif.data.etd)}` : 
            `Shipment ID#${notif.data.shifl_ref} ${pos !== null ? `with PO${pos}` : ''} will arrive on ${formatDate(notif.data.eta)}`
    }
    return subtitle;
}

function formatDate(date) {
    return date !== null ? moment(date).format("MM/DD/YYYY") : "";
}

// window.Echo.private('customer.notification.648')
// .listen('.App\\Events\\PushNotification', e => {
// 	console.log(e);
//     store.commit("notificationNew/ADD_NEW_MESSAGE", e.message);
//     store.commit("notificationNew/ADD_NEW_UNREAD_MESSAGE");
//     store.dispatch("notificationNew/fetchPushedNotification", e.message);
// })