export const accountingMenuItems = [
  {
    title: 'Items',
    path: '/accounting/items'
  },
  {
    title: 'Invoices',
    path: '/accounting/invoices'
  },
  {
    title: 'Customers',
    path: '/accounting/customers'
  },
  {
    title: 'Bills',
    path: '/accounting/bills'
  },
  {
    title: 'Vendors',
    path: '/accounting/vendors'
  },
  {
    title: 'Transactions',
    path: '/accounting/transactions',
    nonQBO: true
  },
  {
    title: 'Manual Journal Entry',
    path: '/accounting/journal-entries',
    nonQBO: true
  },
  {
    title: 'Chart of Accounts',
    path: '/accounting/chart-of-accounts',
    nonQBO: true
  },
  {
    title: 'Accounts',
    path: '/accounting/accounts',
    nonQBO: true
  },
  {
    title: 'Reports',
    path: '/accounting/reports',
    nonQBO: true
  },
  {
    title: 'Reconciliations',
    path: '/accounting/reconciliations'
  },
  {
    title: 'Settings',
    path: '/accounting/settings'
  },
];

export default [
  {
    path: '/accounting',
    name: 'Accounting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Accounting/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('../views/Accounting/Accounts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bills',
        name: 'Bills',
        component: () => import('../views/Accounting/Bills.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bills/:id',
        name: 'BillDetails',
        component: () => import('../views/Accounting/BillDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'customers',
        name: 'Customers',
        component: () => import('../views/Accounting/Customers.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'customers/:id',
        name: 'CustomerDetails',
        component: () => import('../views/Accounting/CustomerDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'items',
        name: 'ItemsAndAccountBased',
        component: () => import('../views/Accounting/ItemsAndAccountBased.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import('../views/Accounting/Invoices.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invoices/:id',
        name: 'InvoiceDetails',
        component: () => import('../views/Accounting/InvoiceDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: () => import('../views/Accounting/Vendors.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'vendors/:id',
        name: 'AccountingVendorDetails',
        component: () => import('../views/Accounting/VendorDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'chart-of-accounts',
        name: 'ChartOfAccounts',
        component: () => import('../views/Accounting/ChartAccounts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'transactions',
        name: 'AccountingTransactions',
        component: () => import('../views/Accounting/Transactions.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'transactions/:id',
        name: 'AccountingTransactionDetails',
        component: () => import('../views/Accounting/TransactionDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'AccountingSettings',
        component: () => import('../views/Accounting/Settings.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'quickbooks/token',
        name: 'QuickbooksToken',
        component: () => import('../views/Accounting/QuickbooksToken.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports',
        name: 'AccountingReports',
        component: () => import('../views/Accounting/Reports.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/:id',
        name: 'AccountingReportDetails',
        component: () => import('../views/Accounting/ReportDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'journal-entries',
        name: 'AccountingJournalsEntries',
        component: () => import('../views/Accounting/ManualJournalEntry.vue'),
        meta: {
          requiresAuth: true
        }
      },

      // Reports
      {
        path: 'reports/general-ledger/:id',
        name: 'AccountingGeneralLedgerReports',
        component: () => import('../views/Accounting/Reports/GeneralLedger.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/profit-and-loss/:id',
        name: 'AccountingProfitAndLossReports',
        component: () => import('../views/Accounting/Reports/ProfitAndLoss.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/trial-balance/:id',
        name: 'AccountingTrialBalanceReports',
        component: () => import('../views/Accounting/Reports/TrialBalance.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/balance-sheet/:id',
        name: 'AccountingBalanceSheetReports',
        component: () => import('../views/Accounting/Reports/BalanceSheet.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/income-summary/:id',
        name: 'AccountingIncomeSummaryReports',
        component: () => import('../views/Accounting/Reports/IncomeSummary.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/expense-summary/:id',
        name: 'AccountingExpenseSummaryReports',
        component: () => import('../views/Accounting/Reports/ExpenseSummary.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reconciliations',
        name: 'AccountingReconciliations',
        component: () => import('../views/Accounting/Reconciliations.vue'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  }
];
